'use strict';

var dialog = require('../../dialog'),
	productStoreInventory = require('../../storeinventory/product'),
	tooltip = require('../../tooltip'),
	sizeguide = require('../../sizeguide'),
	util = require('../../util'),
	addToCart = require('./addToCart'),
	customize = require('./customize'),
	availability = require('./availability'),
	image = require('./image'),
	productNav = require('./productNav'),
	productSet = require('./productSet'),
	recommendations = require('./recommendations'),
	membersOnlyModal = require('./membersOnlyModal'),
	variant = require('./variant'),
	secondaryContent = require('./secondary-content'),
	productTile = require('../../product-tile');

const r = document.querySelector(':root');

/**
 * @description Initialize product detail page with reviews, recommendation and product navigation.
 */
function initializeDom () {
	productNav();
	recommendations();
	tooltip.init();
	membersOnlyModal();
	$('.pdp-power-reviews').tabs();
}

function showMoreSwatchPDP () {
	$('.product-swatches').off('click', 'ul li.open-close');
	$('.product-swatches').on('click', 'ul li.open-close', function (e) {
		if (!$(this).parents('.pt_product-search-result').length) {
			e.preventDefault();
			$(this).parents('.product-swatches').toggleClass('expanded');
		}
	});
}

function stickyElementsInit () {
	if (util.getViewport() >= 880 && $('#pdpMain .product-set-container').length) {
		var window_top = $(window).scrollTop();
		var footer_top = $('#main').siblings('footer').offset().top;
		var div_top = $('.product-col-2.product-set').offset().top - 118;
		var div_height = $('.product-col-1.product-set').height();

		if (window_top + div_height + 118 > footer_top) {
			$('.product-col-1.product-set').removeClass('sticky-image');
		} else if (window_top > div_top) {
			$('.product-col-1.product-set').addClass('sticky-image');
		} else {
			$('.product-col-1.product-set').removeClass('sticky-image');
		}
	}
}

function initProductTopData () {
    var $productMainContainer = $('.product-main-info'),
        $productTopData = $('.product-detail .product-top-data'),
		$quickview = $('#QuickViewDialog');
    if ($('.product-main-info .pdp-hero').length < 1) {
        $('.product-main-info .breadcrumbs-mobile').addClass('short');
    };
    if ($productMainContainer.length && !$quickview.length) {
        if (util.getViewport() < 880) {
            if ($productTopData.length) {
                $productTopData.prependTo($productMainContainer);
            }
        } else {
            if (!$productTopData.length) {
                $productTopData = $('.product-top-data');
                $productTopData.prependTo($('#product-content'));
            }
        }
    }
}

function infoPosition() {
    const r = document.querySelector(':root');
    if ($('.product-info').length > 0) {
        var leftPosition = $('.product-info').offset().left + 'px';
        r.style.setProperty('--overviewLeft', leftPosition);
    }
}

// Initialize the progress slider with proper event bindings and UI setup
function initProgressSlider($sliderContainer, $slider, $slideItems, $slidesToShow) {
    var $progress = $sliderContainer.find('.progress');
	$progress.show();
    $slider.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
        var value = nextSlide >= currentSlide && nextSlide !== slick.slideCount - 1 ? currentSlide + 1 : nextSlide;
        $progress.slider("option", "value", value);
    });

    $slider.on('afterChange', function (event, slick, currentSlide) {
        if (currentSlide !== $progress.slider("value")) {
            $progress.slider("option", "value", currentSlide);
        }
    });

    $progress.slider({
        min: 0,
        max: $slideItems - $slidesToShow,
        slide: function (event, ui) {
            var slick = $slider.slick('getSlick');
            var goTo = Math.round(ui.value * slick.slideCount / $slideItems);
            $slider.slick('goTo', goTo);
        }
    });

    updateProgressMode($progress);
}

// Update progress slider classes for mobile or desktop
function updateProgressMode($progress) {
    if (util.isMobile()) {
        $progress.addClass('mobile').removeClass('desktop');
    } else {
        $progress.addClass('desktop').removeClass('mobile');
    }
}

// Handle resize events to reinitialize the progress slider if necessary
function handleResize($hCarousel, $sliderContainer) {
    var $progress = $sliderContainer.find('.progress');
    var isMobile = window.innerWidth < 768;
    var slidesToShow = isMobile ? 2 : 4;
    var slideItems = $hCarousel.find('.grid-tile').length;

    if ((!isMobile && !$progress.hasClass('desktop')) || (isMobile && !$progress.hasClass('mobile'))) {
        $progress.slider('destroy');
        $progress.removeClass('mobile desktop');
        initProgressSlider($sliderContainer, $hCarousel, slideItems, slidesToShow);
    }
}

// Initialize PDP Recommendations Slider
(window.pdpRecommendationsInit = function () {
    var $hCarousel = $('.product-listing-1x4 .search-result-items');
    if ($hCarousel.hasClass('slick-slider')) {
        return; // Prevent duplicate initialization
    }

    var $sliderContainer = $('.pdp-recommendations');
    var slideItems = $hCarousel.find('.grid-tile').length;
    var slidesToShow = window.innerWidth < 768 ? 2 : 4;

    $hCarousel.slick({
        slidesToScroll: 1,
        slide: '.grid-tile',
        slidesToShow: 4,
        swipeToSlide: true,
        touchThreshold: 20,
        touchMove: true,
        infinite: false,
        responsive: [
            {
                breakpoint: 1200,
                settings: { slidesToShow: 4 }
            },
            {
                breakpoint: 768,
                settings: { slidesToShow: 2 }
            }
        ]
    });

    initProgressSlider($sliderContainer, $hCarousel, slideItems, slidesToShow);

    $(window).on('resize', function () {
        handleResize($hCarousel, $sliderContainer);
    });

    // Additional functionality for showMoreSwatchPDP can be added here
    showMoreSwatchPDP();
})();

// Initialize Shop The Look Slider
function shopTheLook() {
    var $shopTheLook = $('.shop-the-look');
    if (!$shopTheLook.length || $("#QuickViewDialog").length) return;

    var $slider = $shopTheLook.find('.stl-slider');
    var $slide = $shopTheLook.find('.stl-slide');
    var arrows = !util.isMobile();

	if (!$slider.hasClass('slick-slider') && $slide.length > 1) {
        $slider.slick({
			slidesToScroll: 1,
			slide: '.stl-slide',
			slidesToShow: 1,
			swipeToSlide: true,
			touchThreshold: 20,
			touchMove: true,
			infinite: false,
			arrows: arrows,
			respondTo: 'slider',
			adaptiveHeight: true
		});
    }

    if ($slide.length > 1) {
		initProgressSlider($shopTheLook, $slider, $slide.length, 1);
	}

    util.smartResize(function () {
        var $progress = $shopTheLook.find('.progress');
        var isMobile = util.isMobile();

        if ((isMobile && !$progress.hasClass('mobile')) || (!isMobile && !$progress.hasClass('desktop'))) {
            $progress.slider('destroy');
            $progress.removeClass('mobile desktop');
            initProgressSlider($shopTheLook, $slider, $slide.length, 1);
        }
    });

	$(document).on('click', '.select-attributes', function (e) {
		e.preventDefault();
		$('html, body').css('scroll-behavior', 'smooth').scrollTop(0);
		setTimeout(function () {
            $('html, body').css('scroll-behavior', '');
        }, 10);
	});
}

function imageContainerDimensions() {
    if ($(window).width() > $(window).height()) {
        r.style.setProperty('--globalMaxHeight', '100vh');
        r.style.setProperty('--globalDecrementHeight', '28vh');
    }
    if ($(window).width() < $(window).height()) {
        r.style.setProperty('--globalMaxHeight', '100vw');
        r.style.setProperty('--globalDecrementHeight', '45vw');
    }
}

/**
 * @description Initialize event handlers on product detail page
 */
function initializeEvents () {


	var $pdpMain = $('#pdpMain');
	//scrollpane parts
	var scrollPane = $(".scroll-pane"),
	scrollContent = $(".scroll-content");

	addToCart();
	customize();
	availability();
	if ($pdpMain.find('.product-detail.product-set').length > 0) {
		productSet();
	} else {
		variant();
	}
	image();
    initProductTopData();
	sizeguide.init();
	secondaryContent.init();
	shopTheLook();

	$(window).on('shopTheLook', function () {
        shopTheLook();
    });

	if (SitePreferences.STORE_PICKUP) {
		productStoreInventory.init();
	}

	// Add to Wishlist and Add to Gift Registry links behaviors
	$pdpMain.on('click', '[data-action="wishlist"], [data-action="gift-registry"]', function () {
		var data = util.getQueryStringParams($('.pdpForm').serialize());
		if (data.cartAction) {
			delete data.cartAction;
		}
		var url = util.appendParamsToUrl(this.href, data);
		this.setAttribute('href', url);
	});

	// product options
	$pdpMain.on('change', '.product-options select', function () {
		var salesPrice = $pdpMain.find('.product-add-to-cart .price-sales');
		var selectedItem = $(this).children().filter(':selected').first();
		salesPrice.text(selectedItem.data('combined'));
	});

	// product details toggle
	$pdpMain.on('click', '.pdp-expand .tlt-asset-grid', function () {
		$(this).parent().toggleClass('expanded');
	});

	// prevent default behavior of thumbnail link and add this Button
	$pdpMain.on('click', '.thumbnail-link, .unselectable a', function (e) {
		e.preventDefault();
	});

    if ($('#giftcardDeliveryDate').length) {
        var dtToday = new Date();
        var month = dtToday.getMonth() + 1;
        var maxMonth = dtToday.getMonth() + 4;
        var day = dtToday.getDate();
        var year = dtToday.getFullYear();
        if (month < 10)
            month = '0' + month.toString();
        if (maxMonth < 10)
            maxMonth = '0' + maxMonth.toString();
        if (day < 10)
            day = '0' + day.toString();
        var maxDate = year + '-' + maxMonth + '-' + day;
        var minDate = year + '-' + month + '-' + day;
        $('#giftcardDeliveryDate').attr({
            'min': minDate,
            'max': maxDate
        });
    }

    // toggle gift card delivery date UI
    $pdpMain.on('click', '#sendLater', function (e) {
        if (!$('.gift-card-data .later-date').length) {
            $('.expected-date').addClass('later-date');
            this.attr('checked', 'checked');
        }
    });
    // toggle gift card delivery date UI
    $pdpMain.on('click', '#sendNow', function (e) {
        if ($('.gift-card-data .later-date').length) {
            $('#giftcardDeliveryDate').val('');
            $('.expected-date').removeClass('later-date');
            $('.datepicker-overlay').show();
        }
    });

    // toggle gift card delivery date UI
    $pdpMain.on('click', '.datepicker-overlay', function () {
        $('.datepicker-overlay').hide();
        $('#giftcardDeliveryDate').click();
    });

	$pdpMain.on('click', '.pr-snippet-stars, .pr-snippet-review-count', function (e) {
		e.preventDefault();
		$('html, body').animate({
			scrollTop: $('.reviews-container').offset().top
		}, 1000);
	});

	$pdpMain.on('click', '#egcShowExamples', function (e) {
		e.preventDefault();
		dialog.open({
			url: $(e.target).attr('href'),
			options: {
				width: 652
			}
		});
	});

	$(window).on('scroll', function () {
		stickyElementsInit();
	});
    infoPosition();
	util.smartResize(function () {
		if (util.getViewport() < 880 && $('#pdpMain .product-col-1.sticky-image').length) {
			$('#pdpMain .product-col-1').removeClass('sticky-image');
		}
        initProductTopData();
        infoPosition();
		shopTheLook();
	});

	$('#free-returns').on('click', function (e) {
		e.preventDefault();
		dialog.open({
			url: $(e.target).attr('href')
		});
	});

	if (!$('.pdpLeftCarousel ul').hasClass('slick-slider')) {
		$('.pdpLeftCarousel ul').slick();
	}

	if (!$('.pdpPlayers ul').hasClass('slick-slider')) {
		$('.pdpPlayers ul').slick({
			infinite: true,
			slidesToShow: 5,
			slidesToScroll: 5,
			arrows: true,
			responsive: [
				{
					breakpoint: 1199,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 3
					}
				}
			]
		});
	}

	if (!$('.pdp-piu .content-asset').hasClass('slick-slider')) {
		$('.pdp-piu .content-asset').slick({
			infinite: true,
			slidesToShow: 2,
			slidesToScroll: 2,
			centerMode: true,
			centerPadding: '15%',
			arrows: true,
			responsive: [
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
						centerPadding: '40px'
					}
				}
			]
		});
	}

	showMoreSwatchPDP();
	if (!$("#QuickViewDialog").length) {
		var searchPage = $('.pt_product-search-result');
		if (searchPage.length === 0) {
			$('html, body').animate({scrollTop: 0}, 'slow');
		}
	}

	if (window.location.hash == '#overview-anchor') {
		$('html, body').animate({scrollTop: $('#overview-anchor').offset().top - 100}, 'slow');
		return;
	}
	$('.header-dropdown.account button[name="dwfrm_login_login"]').on('click', function (e) {
		var scrollPos = document.documentElement.scrollTop || document.body.scrollTop;
		var href = location.href;
        if (href.indexOf('#') !== -1) {
            href = href.split('#')[0];
        }
		var url = util.appendParamsToUrl(href, {
			'scrollTo': scrollPos
		});
		$('.header-dropdown.account input[name="returnUrl"]').val(url);
	});
	$(window).on('load', function () {
		var searchParams = new URLSearchParams(window.location.search)
		if (searchParams.has('scrollTo')) {
			util.scrollBrowser(searchParams.get('scrollTo'));
		}
    });

    imageContainerDimensions();
    $(window).on('resize', function () {
        imageContainerDimensions();
    });
}

var product = {
	initializeEvents: initializeEvents,
	init: function () {
		initializeDom();
		initializeEvents();
		productTile.init();
	}
};

module.exports = product;

