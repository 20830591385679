'use strict';

var dialog = require('../../dialog');

/**
 * @function
 * @description Initializes the events on the address form (apply, cancel, delete)
 * @param {Element} form The form which will be initialized
 */
function initializeForm() {
    dialog.open({
        url: Urls.showProtectionDialog,
        options: {
            width: 900,
            dialogClass: 'pt_protect',
            open: function () {
                $('#dialog-container #signInButton').on('click', function (e) {
                    e.preventDefault();
                    var form = $(this).closest('form');

                    var options = {
                        url: form.attr('action'),
                        method: 'POST',
                        cache: false,
                        data: form.serialize()
                    };
                    $.ajax(options).done(function (response) {
                        dialog.close();
                        if (response.authenticated) {
                            window.location.href = response.originalHttpURL;
                        } else {
                            window.location.reload();
                        }
                    }).fail(function (xhr, textStatus) {
                        // failed
                        if (textStatus === 'parsererror') {
                            window.alert(Resources.BAD_RESPONSE);
                        } else {
                            window.alert(Resources.SERVER_CONNECTION_ERROR);
                        }
                    });
                });
                $('#dialog-container #cancelButton').on('click', function (e) {
                    e.preventDefault();
                    dialog.close();
                    $('#authorizationRequired').show();
                });
            }
        }
    });
}

var protection = {
	init: function () {
		initializeForm();
	}
};

module.exports = protection;
