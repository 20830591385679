'use strict';
var imagesLoaded = require('imagesloaded'),
	util = require('../../util');

var recprod = {
    init: function () {
    	// jscs:disable
    	/***** Get The Stack Cranking *****/
		if ( typeof FJ_RProducts == 'undefined' ) {

			//object for organizing code
			var FJ_RProducts = {
				/**
				 * debounce - Use this like $( window ).resize( canvas.debounce( function(){}, 500 ) );
				 *
				 * @param function 	fn 		Pass in the function to call after the delay
				 * @param integer 	delay 	The time (in miliseconds) to delay the function from running
				 *
				 * @return function 		returns a function which calls passed in function when the delay has run out
				 */
				debounce: function( fn, delay ) {
					var timer = null;

					return function() {
						var context = this,
							args = arguments;

						clearTimeout( timer );

						timer = setTimeout( function() {
							fn.apply( context, args );
						}, delay );
					};
				},

				init: function() {
					FJ_RProducts.initSliders();
				},

				initSliders: function() {
					if ($('.tabbed-recommendations').length) {
						$('.tabbed-recommendations .fj-rp-slider').each(function (index, element) {
							var $tCarousel = $(this);
							// do not initialize twice
							if ($tCarousel.hasClass('slick-slider')) {
								return;
							}
				
							$tCarousel.slick({
								slidesToShow: 4,
								slidesToScroll: 1,
								arrows: true,
								prevArrow: '<button class="fj-rp-slider-arrow left" type="button"></button>',
								nextArrow: '<button class="fj-rp-slider-arrow right" type="button"></button>',
								dots: false,
								infinite: true,
								cssEase: 'linear',
								responsive: [
									{
										breakpoint: 1200,
										settings: {
											slidesToShow: 4
										}
									},
									{
										breakpoint: 768,
										settings: {
											slidesToShow: 2
										}
									},
									{
										breakpoint: 480,
										settings: {
											slidesToShow: 1,
											centerMode: false
										}
									}
								]
							});
						});
					} else {
						$('.fj-rp-slider').not('.slick-initialized').each( function() {
							$(this)
								.slick({
									slidesToShow: 4,
									slidesToScroll: 1,
									arrows: true,
									prevArrow: '<button class="fj-rp-slider-arrow left" type="button"></button>',
									nextArrow: '<button class="fj-rp-slider-arrow right" type="button"></button>',
									dots: false,
									infinite: true,
									cssEase: 'linear',
									responsive: [
										{
											breakpoint: 1200,
											settings: {
												slidesToShow: 4
											}
										},
										{
											breakpoint: 768,
											settings: {
												slidesToShow: 2
											}
										},
										{
											breakpoint: 480,
											settings: {
												slidesToShow: 1,
												centerMode: false
											}
										}
									]
								});
						} );
					}
				}
			};

			$( document ).ready( function() {
				FJ_RProducts.init();
			} );
		}
    }
};

module.exports = recprod;