'use strict';

var account = require('./account'),
	bonusProductsView = require('../bonus-products-view'),
	dialog = require('../dialog'),
	quickview = require('../quickview'),
	productTile = require('../product-tile'),
	addToCart = require('../pages/product/addToCart'),
	cartStoreInventory = require('../storeinventory/cart'),
	util = require('../util'),
	quickview = require('../quickview'),
	imagesLoaded = require('imagesloaded');

require('../paypalExpress');
require('../expressPaymentMethodsVisibility');

var resources = 'ConfiguratorResources' in window ? window.ConfiguratorResources : {};

/**
 * @private
 * @function
 * @description Binds events to the cart page (edit item's details, bonus item's actions, coupon code entry)
 */
function initializeEvents() {
	$('#cart-table').on('click', '.item-edit-details a:not(.configurable-product .item-edit-details a):not(.сustomizable-product .item-edit-details a)', function (e) {
		e.preventDefault();
		if (util.isMobile() || SitePreferences.DISABLE_QUICK_VIEW) {
			window.location.href = this.href;
			return;
		}
		quickview.show({
			url: e.target.href,
			source: 'cart'
		});
	});

	$('#cart-table').on('click', '.item-image a', function (e) {
		e.preventDefault();

		if (util.isMobile() || SitePreferences.DISABLE_QUICK_VIEW) {
			window.location.href = this.href;
			return;
		}

		var $a = $(this).closest('tr').find('.item-edit-details a:not(.configurable-product .item-edit-details a):not(.сustomizable-product .item-edit-details a)');

		quickview.show({
			url: $a.attr('href'),
			source: 'cart'
		});
	});

	$('#cart-table').on('click', 'a.myjoys-prod-img, a.myjoys-prod-name', function (e) {
		e.preventDefault();
		var pli = $(this).data('pli');
		var editDetailsBtn = '#editDetails_'.concat(pli);
		$(editDetailsBtn).trigger('click');
	});

	// override enter key for coupon code entry
	$('form input[name$="_couponCode"]').on('keydown', function (e) {
		if (e.which === 13 && $(this).val().length === 0) { return false; }
		if (e.which === 13 && $(this).val().length !== 0) {
			$('form button[name$="_addCoupon"]').click();
			return false;
		}
	});
	$('.bonus-products a').on('click', function (e) {
		e.preventDefault();
		bonusProductsView.show(this.href);
	});
	// added to window for CQuotient, self-executing for fallback
	(window.horizontalCarouselInit = function () {
		var $hCarousel = $('.horizontal-carousel');
		// do not initialize twice
		if ($hCarousel.hasClass('slick-slider')) {
			return;
		}
		$hCarousel.slick({
			slidesToScroll: 1,
			slidesToShow: 3,
			responsive: [
				{
					breakpoint: 1200,
					settings: {
						slidesToShow: 2
					}
				}
			]
		});
	})();
	$('.quantity-dropdown, input[id$="_packaging_option"]').on('change', function () {
		$(this).parents('form#cart-items-form').submit();
	});
	$('#go-checkout').on('click', function (e) {
		e.preventDefault();
		$(this).parents('#primary').find('button.go-checkout').click();
	});
	$('button.go-checkout').on('click', function (e) {
		e.preventDefault();
		if ($(this).data().hasOwnProperty('dialogurl')) {
			dialog.open({
				url: $(this).data('dialogurl'),
				options: {
					title: resources.PREMIUM_MEMBERSHIP_REQUIRED_TITLE,
					open: function () {
						$('.premium-membership-required-removal').on('click', 'button.close-dialog', function (e) {
							e.preventDefault();
							$('.ui-dialog-content').dialog('close');
						});
					}
				}
			});

			return false;
		}

		var button = $(this);
		var $form = $(this).parents('form');
		$('<input/>').attr({
			type: 'hidden',
			name: button.attr('name'),
			value: button.attr('value')
		}).appendTo($form);

		$form.submit();
	});
	productTile.showMoreSwatch();
	productTile.swatchImageCkick();

	//Promo code toggle
	$('.cart-coupon-code h2').click(function () {
		$(this).toggleClass('close');
		$(this).next('.cart-coupon-wrapper').toggleClass('close');
	});

    $('.action-carousel').on('click', '.moretxt, .closetxt', function (e) {
		e.preventDefault();
		$('.product-swatches').removeClass('expanded');

		var swatchURL = $(this).parents('.swatch-list').find('.swatch');

        if (util.isMobile() || SitePreferences.DISABLE_QUICK_VIEW) {
			window.location.href = swatchURL.attr('href');
			return;
		}

		quickview.show({
			url: swatchURL.attr('href'),
			source: 'quickview'
		});
	});

	$('.action-carousel').on('click', '.swatch, .thumb-link', function (e) {
		e.preventDefault();

        if (util.isMobile() || SitePreferences.DISABLE_QUICK_VIEW) {
			window.location.href = this.href;
			return;
		}

		quickview.show({
			url: $(this).attr('href'),
			source: 'quickview'
		});
	});
	imagesLoaded('.category-rocommendations').on('done', function () {
        if ($('.category-recommendations .listing-item').length > 5) {
            $('.category-recommendations .category-listing').addClass('more-than-5');
        }
        $('.category-recommendations').addClass('images-loaded');
        $('.category-recommendations .categoty-image').syncHeight();
    });
}

$(window).on('resize', function () {
    if ($('.category-recommendations.images-loaded').length) {
        $('.category-recommendations .categoty-image').css('height', 'auto');
        $('.category-recommendations .categoty-image').syncHeight();
    }
});
exports.init = function () {
	initializeEvents();
	addToCart();
	if (SitePreferences.STORE_PICKUP) {
		cartStoreInventory.init();
	}
	account.initCartLogin();
};
