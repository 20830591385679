'use strict';

var customTaxID = {
	initCustomTaxIDFieldValidation: function (formSelector) {
		var $form = $(formSelector);
		var customerTaxID = $form.find('#customerTaxID');
		if (customerTaxID.length > 0) {
			var customerTaxIDpattern = customerTaxID.attr('pattern');
			var customerTaxIDPatternMsg = customerTaxID.attr('data-regexerror');

			$.validator.addMethod("pattern", function (value, element, param) {
				var regex = param instanceof RegExp ? param : new RegExp(param);
				return this.optional(element) || regex.test(value);
			}, customerTaxIDPatternMsg);

			$form.validate({
				rules: {
					customerTaxID: {
						required: true,
						pattern: true
					}
				}
			});

			$("#customerTaxID").rules("add", {
				required: true,
				pattern: customerTaxIDpattern
			});
		}
	}
};

module.exports = customTaxID;