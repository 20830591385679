'use strict';
var util = require('../util'),
    smschannel = require('../smschannel/smschannel');

var footer = {
    init: function () {
    	// jscs:disable
    	if ( typeof FJ_Footer == 'undefined' ) {
			//object for organizing code
			var FJ_Footer = {
				/**
				 * debounce - Use this like $( window ).resize( canvas.debounce( function(){}, 500 ) );
				 *
				 * @param function 	fn 		Pass in the function to call after the delay
				 * @param integer 	delay 	The time (in miliseconds) to delay the function from running
				 *
				 * @return function 		returns a function which calls passed in function when the delay has run out
				 */
				debounce: function( fn, delay ) {
					var timer = null;

					return function() {
						var context = this,
							args = arguments;

						clearTimeout( timer );

						timer = setTimeout( function() {
							fn.apply( context, args );
						}, delay );
					};
				},

				init: function() {
					$( '.fj-main-slider' ).click( function( e ) {
						if ( e.target !== $( '.js-footer-newsletter' )[0] ) {
							$( '.js-footer-newsletter' ).blur();
						}
					} );
				}
			};

			$( document ).ready( function() {
				FJ_Footer.init();
			} );
		}

		$(document).on('click', '.js-footer__links__title__collapse-button', function() {
			$(this).toggleClass('collapsed');
			$(this).parents('.footer__links__wrapper').find('.footer__links').toggleClass('collapsed');
		});

		$(document).on('click', '.js-footer__insider__buttons__button, .js-header__insider__buttons__button, .button-order-sign-in', function() {
            $('.foot--footjoy .members-only-modal .members-only-account .checkbox-another').each(function() {
                var currentId = $(this).attr('id')
                if (currentId.toLowerCase().indexOf("_footer") == -1) {
                    $(this).attr('id', currentId + '_footer');
                    $(this).next('label').attr('for', currentId + '_footer');
                    $(this).next().on('click', function(e) {
                        e.preventDefault();
                        $(this).prev().trigger('click');
                    });
                }
            });

            var $membersOnlyModal = $('.foot--footjoy .members-only-modal');
			var $button = $(this);

			if (!$membersOnlyModal.length) {
				return;
			}

			if ($button.data('url')) {
				var url = $button.data('url') === '/' ? location.href : $button.data('url');
				$membersOnlyModal.find('input[name="returnUrl"]').val(url);
			}

			$membersOnlyModal.dialog({
				height: 'auto',
				modal: true,
				overlay: {
					opacity: 0.5,
					background: 'black'
				},
				dialogClass: 'members-only-dialog',
				draggable: false,
				resizable: false,
				width: 'auto',
				close: function () {
                    $('html').removeClass('freeze-scroll');
					$membersOnlyModal.dialog('destroy');
				}
			});

			var $button = $(this);
			$membersOnlyModal.find('.members-only-account').accordion({
				active: $button.data('action') === 'join' ? 1 : 0
			});
		});

        if (SitePreferences.SMS_CHANNEL_ENABLED && SitePreferences.SMS_MARKETING_ENABLED) {
            // Add event to submit 'sign up' form via ajax for SMS Marketing only
            $('form#email-alert-signup').on('submit', function (e) {
                e.preventDefault();

                var form = $(this)
                var data = $(form).serialize();
                var url = $(form).attr('action');
                url = util.appendParamsToUrl(url, {
                    'format': 'ajax'
                });
                $.ajax({
                    type: 'POST',
                    url: url,
                    data: data,
                    success: function (res) {
                        if (typeof res === 'object' && res !== null) {
                            var success = !res.error;
                            var redirectUrl = res.redirectUrl;

                            if (success) {
                                $('#footerSignUpBySMSLink').click();
                            } else if (!success && redirectUrl){
                                window.location = redirectUrl;
                            }
                        } 
                    }
                });
            });

            // Add event to submit for Register Step1 from on header modal
            $(document).on('click', '.members-only-modal .members-only-account #regiserStep1 form button[type="submit"]', function (e) {
                e.preventDefault();
                var form = $(this).closest('form');

                var data = $(form).serialize();
                var url = $(form).attr('action');
                url = util.appendParamsToUrl(url, {
                    'format': 'ajax'
                });
                $.ajax({
                    type: 'POST',
                    url: url,
                    data: data,
                    success: function (res) {
                        if (typeof res === 'object' && res !== null && res.redirectUrl) {
                            var redirectUrl = res.redirectUrl;
                            var profileSecureKeyValue = res.profileSecureKeyValue;
                            // hide form 1 and open form 2
                            $('.members-only-modal .members-only-account').find('#regiserStep1').hide();
                            $('.members-only-modal .members-only-account').find('#regiserStep2').show();
                            $('.members-only-modal .members-only-account #regiserStep2').find('input[name="dwfrm_profile_securekey"]').attr('value', profileSecureKeyValue);
                            $('.members-only-modal .members-only-account #regiserStep2').find('input[name="returnUrl"]').attr('value', redirectUrl);

                            // hide category option section in case if eNews is not selected
                            // to match logic of selection category options on Account-Preference page.
                            // Set addtoemaillist input with selected value got from register form #1
                            // as addtoemaillist is used in exusting subscription logic
                            var isAddToEmailChecked = $('.members-only-modal .members-only-account').find('#regiserStep1 input[name="dwfrm_profile_customer_addtoemaillist"]').is(':checked');
                            if (!isAddToEmailChecked) {
                                $('.members-only-modal .members-only-account #regiserStep2').find('.category-options').removeClass('visually-hidden').addClass('visually-hidden');
                            } else {
                                $('.members-only-modal .members-only-account #regiserStep2').find('input[name="dwfrm_profile_customer_addtoemaillist"]').attr('checked', 'checked');
                            }
                        } else {
                            // it will load login page with errors as it was before
                            // when something was wrong with registration via header
                            var headerRegisterFailed = window.Urls.headerRegisterFailed;
                            window.location = headerRegisterFailed;
                        }
                    }
                });
            });
            // Add event to submit Register Step2 from on members only modal
            $(document).on('click', '.members-only-modal .members-only-account #regiserStep2 form button[type="submit"]', function (e) {
                e.preventDefault();

                var errorPlaceHolderElement = $('.members-only-modal .members-only-account #regiserStep2 #errorPlaceHolder');
                var parentElement = $(this).closest('form');

                smschannel.subscribe(
                    smschannel.CREATED_BY.SITE_REGISTRATION,
                    parentElement,
                    errorPlaceHolderElement,
                    function () {
                        // submit form with preferences
                        parentElement.submit();
                    }
                );
            });

            // Footer Sign Up By SMS Dialog
            $(document).on('click', '#footerSignUpBySMSLink', function(e) {
                e.preventDefault();
                var savedEmail = '';

                $.ajax({
                    url: Urls.smsGetSavedEmail,
                    method: 'GET',
                    dataType: 'json',
                    async: false,
                }).done(function (data) {
                    if (data && data.savedEmail) {
                        savedEmail = data.savedEmail;
                    }
                }).always(function() {
                    if ($('.signup-sms-modal #signUpBySmsModalForm #fi_sms_email').length > 0) {
                        $('.signup-sms-modal #signUpBySmsModalForm #fi_sms_email').val(savedEmail);
                    }
                });

                var $signupBySmsModal = $('.signup-sms-modal');

                if (!$signupBySmsModal.length) {
                    return;
                }

                $('.signup-sms-modal #signUpBySmsModalForm').removeClass("visually-hidden");
                $('.signup-sms-modal #signUpBySmsResultForm').removeClass("visually-hidden").addClass("visually-hidden");

                $signupBySmsModal.dialog({
                    height: 'auto',
                    modal: true,
                    overlay: {
                        opacity: 0.5,
                        background: 'black'
                    },
                    dialogClass: 'signup-sms-dialog',
                    draggable: false,
                    resizable: false,
                    width: 'auto',
                    close: function () {
                        $('.signup-sms-modal #signUpBySmsModalForm').removeClass("visually-hidden");
                        $('.signup-sms-modal .signup-sms-modal-heading').removeClass("visually-hidden");
                        $('.signup-sms-modal #signUpBySmsResultForm').addClass("visually-hidden");
                        $('.signup-sms-modal form').find('input.error').removeClass('error');
                        $('.signup-sms-modal form').find('span.error').remove();
                        $('.signup-sms-modal #fi_sms_marketing').removeClass('error');
                        if ($('.signup-sms-modal #fi_sms_marketing').hasClass('initially-checked')) {
                            $('.signup-sms-modal #fi_sms_marketing').prop('checked', true);
                        } else {
                            $('.signup-sms-modal #fi_sms_marketing').prop('checked', false);
                        }
                        $('.signup-sms-modal input.smschannelphone ').val('');
                        $('.signup-sms-modal #errorPlaceHolder').html('');
                        $signupBySmsModal.dialog('destroy');
                    }
                });
            });

            // SMS Dialog called via parameter "utm_content"
            var urlParams = new URLSearchParams(window.location.search);
            var utmContent = urlParams.get('utm_content');
            if (utmContent == 'mobileoptin') {
                $('#footerSignUpBySMSLink').click();
            }

            // Add event to submit sms from on Sign Up By SMS modal
            $(document).on('click', '.signup-sms-modal #signUpBySmsModalForm form button[type="submit"]', function (e) {
                e.preventDefault();

                var errorPlaceHolderElement = $('.signup-sms-modal #signUpBySmsModalForm #errorPlaceHolder');
                var parentElement = $(this).closest('form');

                $('#signUpBySmsModalForm input.is-checking').each(function () {
                    if ($(this).val().length === 0) {
                        $(this).closest('.form-row').find('span.error').remove();
                        $(this).addClass('error');
                        $(this).after('<span id="' + $(this).attr('id') + '-error" class="error">' + Resources.VALIDATE_REQUIRED + '</span>');
                    }
                });
                if (!$('.signup-sms-modal #fi_sms_marketing').is(':checked')) {
                    $('.signup-sms-modal #fi_sms_marketing').addClass('error');
                } else {
                    $('.signup-sms-modal #fi_sms_marketing').removeClass('error');
                }
                if ($('#signUpBySmsModalForm').find('input.error').length > 0) {
                    return false;
                }

                smschannel.subscribe(
                    smschannel.CREATED_BY.SITE_FOOTER,
                    parentElement,
                    errorPlaceHolderElement,
                    function () {
                        $('.signup-sms-modal #signUpBySmsModalForm').addClass("visually-hidden");
                        $('.signup-sms-modal .signup-sms-modal-heading').addClass("visually-hidden");
                        $('.signup-sms-modal #signUpBySmsResultForm').removeClass("visually-hidden");
                    }
                );
            });
        }
    }
};

module.exports = footer;
