'use strict';

var dialog = require('../../dialog'),
minicart = require('../../minicart'),
bonusProductsView = require('../../bonus-products-view'),
page = require('../../page'),
util = require('../../util'),
addToCartPopup = require('./addToCartPopup'),
membersOnlyModal = require('./membersOnlyModal'),
TPromise = require('promise'),
_ = require('lodash');

const myFJDesignsKey = 'myFJDesigns';

/**
Saves custom FJ designs to local storage and handles expiration.
@param {Object} configObject - Configuration object containing design details.
@param {string} configObject.custom_instance_id - Custom instance ID of the design.
@param {string} configObject.originalMasterId - Original master ID of the design.
@param {string} configObject.original_instance_id - Original instance ID of the design (if updating an existing design).
@param {string} configObject.myJoysImageUrl0 - URL of the design image.
@param {number} maxDays - Number of days the designs should be kept in local storage.
@returns {void}
@description This function saves custom FJ designs to local storage, and checks for expiration of existing designs.
If the designs have expired, they are removed from storage. If a design is new, it is added to the storage. If a design
is being updated, the old design is removed and the new design is added.
*/
function saveMyFJDesignsToLocalStorage(configObject, maxDays) {
	const myFJDesignsMaxDays = parseInt(maxDays, 10);
	const myFJDesigns = JSON.parse(window.localStorage.getItem(myFJDesignsKey)) || {lastUpdated: null, designs: {}};

	// Check if myFJDesigns is expired
	const currentDate = new Date();
	const lastUpdated = new Date(myFJDesigns.lastUpdated);
	const expirationDate = new Date(lastUpdated.getTime() + (myFJDesignsMaxDays * 24 * 60 * 60 * 1000));

	if (currentDate > expirationDate) {
		myFJDesigns.designs = {};
		myFJDesigns.lastUpdated = currentDate.toISOString();
	} else {
		myFJDesigns.lastUpdated = currentDate.toISOString();
	}

	// Check if this is a new design or an update to an existing design
	const custom_instance_id = configObject.custom_instance_id;
	const originalMasterId = configObject.pdpMasterProduct || configObject.originalMasterId;
	const originalInstanceId = configObject.originalInstanceId;
	const myJoysImageUrl = configObject.myJoysImageUrl0 || '';
	const skuDesigns = myFJDesigns.designs[originalMasterId] || [];
	const newDesignObj = {instance: custom_instance_id, originalMasterId: originalMasterId, myJoysImageUrl: myJoysImageUrl}

	if (!originalInstanceId) {
		// This is a new design
		if (!skuDesigns.some(function (design) { return 'instance' in design && design.instance === custom_instance_id})) {
			skuDesigns.push(newDesignObj);
		}
	} else {
		// This is an update to an existing design
		const existingDesignIndex = skuDesigns.findIndex(function (design) { return design.instance === originalInstanceId});

		if (existingDesignIndex !== -1) {
			skuDesigns.splice(existingDesignIndex, 1);
		}

		skuDesigns.push(newDesignObj);
	}

	myFJDesigns.designs[originalMasterId] = skuDesigns;

	window.localStorage.setItem(myFJDesignsKey, JSON.stringify(myFJDesigns));
}

/**
Renders the FJ designs saved in local storage for a given master ID.
@param {string} masterID - The master ID of the product to render FJ designs for.
@returns {void}
**/
function renderSwatchesFromLocalStorage(masterID) {
	const myFJDesigns = JSON.parse(window.localStorage.getItem(myFJDesignsKey)) || {lastUpdated: null, designs: {}};
	// Find the swatch element container
	const designSwatches = $('.pdp-my-designs');
	const swatchContainer = designSwatches.length ? designSwatches.find('.swatches') : null;

	const customProductDesigns = myFJDesigns.designs[masterID] || [];

	if (customProductDesigns.length === 0 || !swatchContainer) {
		designSwatches.addClass('visually-hidden');
		return;
	} else {
		designSwatches.removeClass('visually-hidden');
	}

	// Add new swatches based on the saved designs
	swatchContainer.empty();
	customProductDesigns.forEach(function (design) {
		const li = document.createElement('li');
		const span = document.createElement('span');
		const img = document.createElement('img');

		li.classList.add('custom-design-swatch');
		li.setAttribute('data-master', masterID);
		li.setAttribute('data-instance', design.instance);
		img.classList.add('custom-design-swatch-img');
		img.setAttribute('alt', design.instance);
		img.setAttribute('src', design.myJoysImageUrl);
		span.setAttribute('role', 'button');

		span.appendChild(img);
		li.appendChild(span);
		swatchContainer.append(li);
	});
}

// Append params to redirect URL for Saved Designs
function appendParamToURL(url, name, value) {
	var separator = url.indexOf('?') !== -1 ? '&' : '?';
	return url + separator + name + '=' + encodeURIComponent(value);
}

$('body').on('customize:saveMyFJDesigns', function (event, data) {
	saveMyFJDesignsToLocalStorage(data.instance, data.myJoysSavedDesignMaxDays);
});

/**
* @description Handler to handle the customize event
*/
var customize = function (e) {
	e.preventDefault();
	var myJoysProductCID = ($(e.target).data('prodMyjoys'));
	var selectBothSize = $('#chkDifferentSize').is(":checked");
	var myJoysVendorKey = ($(e.target).data('customizerVendor'));
	var myJoysCustomizerDCAPIKey = ($(e.target).data('customizerKey'));
	var myJoysCustomizerDCEnvironment = ($(e.target).data('customizerEnv'));
	var myJoysCustomizerDCdisableCaching = ($(e.target).data('customizerCache'));
	var myJoysCustomizerDCCartURL = ($(e.target).data('customizerCart'));
	var myJoysCustomizerDCWishlistURL = ($(e.target).data('customizerWishlist'));
	var myJoysSavedDesignMaxDays = ($(e.target).attr('data-saved-designs-max-days'));
	var myJoysWidth = $('.select-width').find('li.selected').length ? $('.select-width').find('li.selected').text().trim().toUpperCase() : '';
	var myJoysSize = $('.select-size, .select-sizeW').find('li.selected').length ? $('.select-size, .select-sizeW').find('li.selected').data('size').toString().trim() : '';

	if (myJoysWidth == '' || myJoysWidth == null) {
		myJoysWidth = undefined;
	}

	switch (myJoysWidth) {
		case "NARROW":
		myJoysWidth = "N"
		break;
		case "MEDIUM":
		myJoysWidth = "M"
		break;
		case "WIDE":
		myJoysWidth = "W"
		break;
		case "EXTRA WIDE":
		myJoysWidth = "XW"
		break;
		default:
		myJoysWidth = undefined
		break;
	}

	if (myJoysSize == '' || myJoysSize == null) {
		myJoysSize = undefined;
	} else {
		myJoysSize = (myJoysSize * 10) + '';
		if (myJoysSize.length == 2) {
			myJoysSize = '0' + myJoysSize;
		}
	}

	//var prodId = $(e.target).data('prodId');
	var standardProdId = $(e.target).data('standardProdId');
	CustomizerWidget.createWidget({
		apiKey: myJoysCustomizerDCAPIKey,
		products: '',
		environment: myJoysCustomizerDCEnvironment,
		vendor: myJoysVendorKey,
		package: '',
		instance: myJoysProductCID,
		disableCaching: myJoysCustomizerDCdisableCaching,
		cartServiceType: 'JSONP',
		cartUrl: myJoysCustomizerDCCartURL,
		wishlistUrl: myJoysCustomizerDCWishlistURL,
		originalMasterId: standardProdId,
		widthLeft: myJoysWidth,
		widthRight: myJoysWidth,
		sizeLeft: myJoysSize,
		sizeRight: myJoysSize,
		preferDifferentSizes: selectBothSize,
		onSaveDesign: function (instance) {
			$('body').trigger('customize:saveMyFJDesigns', {
				instance: instance,
				myJoysSavedDesignMaxDays: myJoysSavedDesignMaxDays
			});
			var url = appendParamToURL(Urls.getProductUrl, 'pid', instance.pdpMasterProduct || instance.originalMasterId);
			location.href = url;
		}
	}).then(function (instance) {
		// create new URL and append a new parameter
		var urlSearchParams = new URLSearchParams(window.location.search);
		urlSearchParams.set("customizeropen", "true");
		// add customizerOpen parameter to existing parameters
		var existingParams = urlSearchParams.toString();
		var newParams = existingParams ? "?" + existingParams : "";
		// update pushstate with new URL
		var newUrl = window.location.pathname + newParams;
		history.pushState(null, "", newUrl);
		var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
		if (isSafari) {
			sessionStorage.setItem("reloadUrl", newUrl);
		}
		// capture the back button event and close the widget
		$(window).on('popstate', function (e) {
			window.location.reload();
		});
	});
};

$(window).bind("pageshow", function (event) {
	var reloadUrl = sessionStorage.getItem("reloadUrl");
	var historyTraversal = event.persisted || (typeof window.performance !== "undefined" && window.performance.navigation.type === 2);

    if (historyTraversal && reloadUrl) {
		location.href = reloadUrl;
		sessionStorage.removeItem("reloadUrl");
    }
});

/**
* @description Make the AJAX request to add an item to cart
* @param {Element} form The form element that contains the item quantity and ID data
* @returns {Promise}
*/
var addItemToCart = function (form) {
	var $form = $(form),
	$qty = $form.find('select[name="Quantity"]');
	if ($qty.length === 0 || isNaN($qty.val()) || parseInt($qty.val(), 10) === 0) {
		$qty.val('1');
	}
	return TPromise.resolve($.ajax({
		type: 'POST',
		url: util.ajaxUrl(Urls.addProduct),
		data: $form.serialize()
	}));
};

/**
* @description Make the AJAX request to add a MyJoys item to cart
* @param {Element} form The form element that contains the item quantity and ID data
* @returns {Promise}
*/
var addMyJoysItemToCart = function (form) {
	var $form = $(form),
	$qty = $form.find('select[name="Quantity"]');
	if ($qty.length === 0 || isNaN($qty.val()) || parseInt($qty.val(), 10) === 0) {
		$qty.val('1');
	}
	return TPromise.resolve($.ajax({
		type: 'POST',
		url: util.ajaxUrl(Urls.addMyJoysFromWishlist),
		data: $form.serialize()
	}));
};


/**
* @description Handler to handle the add to cart event
*/
var addToCart = function (e) {
	e.preventDefault();
	var $this = $(this);

	if ($this.hasClass('members-only')) {
		membersOnlyModal();
		return false;
	}

	if ($this.hasClass('disabled') || $this.is(':disabled')) {
        var $warningBoxes = $this.closest('.product-detail').find('ul.warning-box');
        if ($warningBoxes.length) {
			var customizerType = ($(e.target).data('customizerType'));
			var myJoysProductCID = ($(e.target).data('prodMyjoys'));
			if (customizerType == 'DC' && myJoysProductCID != '') {
				customize(e);
			} else {
				var unselectedValues = [];
				$warningBoxes.each(function () {
					if (!$(this).find('.selected').length) {
						unselectedValues.push($(this).closest('.must-select').attr('data-attribute'));
						$(this).addClass('error');
						if ($(this).closest('.custom-select-field').find('.select-header').length) {
							$(this).closest('.custom-select-field').find('.select-header').addClass('error');
						}
					}
				});
				$('.low-inventory-msg').text('Please select a ' + (unselectedValues.length === 2 ? unselectedValues.join(' and ') : unselectedValues.join(', ')));
				$(this).blur();
			}
        }

		if ($this.is('#add-gc-to-cart')) {
			var $form = $this.parents('form');
			$form.valid();
		}
		return false;
	}

	if ($this.hasClass('wedgeworks')) {
		window.location.href = $('input[name="customizeURL"]').val();
		return false;
	}

	var $form = $this.closest('form');
	if ($form.length === 0) {
		$form = $(this.form);
	}
	addItemToCart($form).then(function (response) {
		var $uuid = $form.find('input[name="uuid"]');
		// for A/B testing
		if ($('.mobile-a2c-participant').length && util.getViewport() < 768) {
			mobileAddToCartIntercept();
			return;
		}
		if ($uuid.length > 0 && $uuid.val().length > 0 || window.pageContext.ns === 'cart' || window.pageContext.ns === 'checkout' || window.pageContext.ns === 'configurator' || window.pageContext.ns === 'customizer') {
			page.redirect(Urls.cartShow);
		} else {
			// do not close quickview if adding individual item that is part of product set
			// @TODO should notify the user some other way that the add action has completed successfully
			if (!$(this).hasClass('sub-product-item')) {
				dialog.close();
			}
			if (response.indexOf('max-quantity-error') >= 0) {
				dialog.open({
					url: Urls.maxQuantityError
				});
			} else {
				var showMiniCard = true;
				if ($this.hasClass('showAddToCartPopup')) {
					$this.html(response);
					var pliuuid = $this.find('.pliuuid');
					var pliqty = $this.find('.pliqty');
					if (pliuuid.length > 0) {
						addToCartPopup.show(pliuuid.text(), pliqty.text());
					}
					showMiniCard = false;
				}
				if (util.getViewport() >= 768 || $('body.enable-slideout-cart').length) {
					minicart.show(response, showMiniCard);
				}
				if (util.getViewport() < 768 && !document.querySelector('.bonus-discount-container') && showMiniCard && !$('body.enable-slideout-cart').length) {
					document.cookie = 'addedtocart=1; path=/';
					page.redirect(Urls.cartShow);
				}
			}
		}
	}.bind(this));
};

/**
* @description Open mobile cart intercept dialog
*/
var mobileAddToCartIntercept = function () {
	dialog.open({
		url: Urls.mobileAddToCartIntercept,
		options: {
			dialogClass: 'cart-intercept',
			open: function () {
				$('.mobile-a2c-intercept-continue').on('click', function () {
					dialog.close();
				});
				$('.mobile-a2c-intercept-cart').on('click', function () {
					page.redirect(Urls.cartShow);
				});
			}
		}
	});
};

/**
* @description Handler to handle the add to cart event
*/
var addMyJoysToCart = function (e) {
	e.preventDefault();
	var $form = $(this).closest('form');
	if ($form.length === 0) {
		$form = $(this.form);
	}
	addMyJoysItemToCart($form).then(function (response) {
		var $uuid = $form.find('input[name="uuid"]');
		if ($uuid.length > 0 && $uuid.val().length > 0 || util.getViewport() < 768) {
			page.redirect(Urls.cartShow);
		} else {
			// do not close quickview if adding individual item that is part of product set
			// @TODO should notify the user some other way that the add action has completed successfully
			if (!$(this).hasClass('sub-product-item')) {
				dialog.close();
			}
			minicart.show(response);
		}
	}.bind(this));
};


/**
* @description Handler to handle the add all items to cart event
*/
var addAllToCart = function (e) {
	e.preventDefault();
	var $productForms = $('#product-set-list').find('form').toArray();
	TPromise.all(_.map($productForms, addItemToCart))
	.then(function (responses) {
		dialog.close();
		if (util.getViewport() < 768) {
			page.redirect(Urls.cartShow);
		} else {
			// show the final response only, which would include all the other items
			minicart.show(responses[responses.length - 1]);
		}
	});
};

/**
* @function
* @description Binds the click event to a given target for the add-to-cart handling
* @param {Element} target The target on which an add to cart event-handler will be set
*/
module.exports = function (target) {
	$('.add-to-cart[disabled], .add-to-cart.disabled').attr('title', $('.availability-msg').text());

	renderSwatchesFromLocalStorage($('#design-your-own-swatch span').attr('data-standard-prod-id'));

	if (target) {
		target.off('click');
		target.on('click', '.add-to-cart', addToCart);
		target.on('click', '.add-myjoys-to-cart', addMyJoysToCart);
	} else {
		$('.add-to-cart').off('click').on('click', addToCart);
		$('.add-myjoys-to-cart').on('click', addMyJoysToCart);
	}

	$('#add-all-to-cart').off('click').on('click', addAllToCart);
};
