'use strict';

var util = require('../util');

// Created By values for FI call. They are the same as in custom preference "FIConfigurationInterests"
var CREATED_BY = {
    SITE_CHECKOUT: 'site-checkout',
    SITE_REGISTRATION: 'site-registration',
    MY_ACCOUNT: 'site-myaccount',
    SITE_FOOTER: 'site_footer',
    STANDALONE: 'site_standalone'
};

var smsChannel = {
    // Created By enum
    CREATED_BY: CREATED_BY,
    /**
    * @function
    * @description Subscribe via ajax
    */
    subscribe: function (createdBy, parentElement, errorPlaceholderElement, onSuccess, onFailed) {
        // empty error placeholder
        $(errorPlaceholderElement).empty();

        var url = window.Urls.smsSubscribe;
        url = util.appendParamsToUrl(url, {
            'format': 'ajax'
        });

        url = util.appendParamsToUrl(url, {
            'fi_sms_createdby': createdBy
        });

        if ($(parentElement).find('input[name="fi_sms_phonemobile"]').length > 0) {
            var fi_sms_phonemobile = $(parentElement).find('input[name="fi_sms_phonemobile"]').val();
            if (fi_sms_phonemobile != '') {
                url = util.appendParamsToUrl(url, {
                    'fi_sms_phonemobile': fi_sms_phonemobile
                });
            }
        }

        if ($(parentElement).find('input[name="fi_sms_email"]').length > 0) {
            var fi_sms_email = $(parentElement).find('input[name="fi_sms_email"]').val();
            if (fi_sms_email != '') {
                url = util.appendParamsToUrl(url, {
                    'fi_sms_email': fi_sms_email
                });
            }
        }

        if ($(parentElement).find('input[name="fi_sms_save_email"]').length > 0) {
            var fi_sms_save_email = $(parentElement).find('input[name="fi_sms_save_email"]').val();
            if (fi_sms_save_email == 'true') {
                url = util.appendParamsToUrl(url, {
                    'fi_sms_save_email': fi_sms_save_email
                });
            }
        }

        if ($(parentElement).find('input[name="fi_sms_marketing"]').length > 0) {
            var fi_sms_marketing = $(parentElement).find('input[name="fi_sms_marketing"]').is(':checked');
            url = util.appendParamsToUrl(url, {
                'fi_sms_marketing': fi_sms_marketing ? 'checked' : 'unchecked'
            });
        }

        if ($(parentElement).find('input[name="fi_sms_transactional"]').length > 0) {
            var fi_sms_transactional = $(parentElement).find('input[name="fi_sms_transactional"]').is(':checked');
            url = util.appendParamsToUrl(url, {
                'fi_sms_transactional': fi_sms_transactional ? 'checked' : 'unchecked'
            });
        }

        $.ajax({
            type: 'GET',
            url: url,
            // data: data,
            success: function (res) {
                if (typeof res === 'object' && res !== null) {
                    if (res.success) {
                        if (res.returnUrl) {
                            window.location = res.returnUrl;
                        }
                        if (onSuccess) {
                            onSuccess();
                        }
                    } else if (res.errorMsg) {
                        $(errorPlaceholderElement).text(res.errorMsg);
                        if (onFailed) {
                            onFailed();
                        }
                    } else {
                        window.console.log('Failed SMSChannel subscription. Something wrong');
                    }
                } else {
                    window.console.log('Failed SMSChannel subscription');
                }
            }
        });
    },
    /**
    * @function
    * @description Init SMS Form
    */
    initSMSForm: function () {
        // We set hidden "fi_sms_marketing" input with value "checked"/"unchecked"
        // based on selected/unselected checkbox "fi_sms_marketing_form".
        // Because when we submit form with unselected checbox it won't be present in httpParameters on the SFCC server.
        // That's why we set value "checked"/"unchecked" to have opportunity getting it on SFCC server
        $('input[name="fi_sms_marketing_form"]').on('click', function (e) {
            // updated checkbox parameter value as it is working with "checked" or "unchecked" values
            var parentElement = $(this).closest('form');

            if ($(parentElement).find('input[name="fi_sms_marketing_form"]').length > 0) {
                var fi_sms_marketing = $(parentElement).find('input[name="fi_sms_marketing_form"]').is(':checked');
                var updatedMarketingValue = fi_sms_marketing ? 'checked' : 'unchecked';
                $(parentElement).find('input[name="fi_sms_marketing"]').val(updatedMarketingValue);
            }
        });
        // We set hidden "fi_sms_transactional" input with value "checked"/"unchecked"
        // based on selected/unselected checkbox "fi_sms_transactional_form".
        // Because when we submit form with unselected checbox it won't be present in httpParameters on the SFCC server.
        // That's why we set value "checked"/"unchecked" to have opportunity getting it on SFCC server
        $('input[name="fi_sms_transactional_form"]').on('click', function (e) {
            // updated checkbox parameter value as it is working with "checked" or "unchecked" values
            var parentElement = $(this).closest('form');

            if ($(parentElement).find('input[name="fi_sms_transactional_form"]').length > 0) {
                var fi_sms_transactional = $(parentElement).find('input[name="fi_sms_transactional_form"]').is(':checked');
                var updatedTransactionalValue = fi_sms_transactional ? 'checked' : 'unchecked';
                $(parentElement).find('input[name="fi_sms_transactional"]').val(updatedTransactionalValue);
             }
        });
    }
};

module.exports = smsChannel;
