module.exports.init = function () {
    $(document).on('click', '.js-accordion__item__button', function () {
        var $button = $(this);
        var $container = $button.next('.js-accordion__item__container');

        $('.js-accordion__item__button:not(.collapsed)').not($button).addClass('collapsed');
        $('.js-accordion__item__container:not(.collapsed)').not($container).addClass('collapsed');

        $button.toggleClass('collapsed');
        $container.toggleClass('collapsed');
    });
};
