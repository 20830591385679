'use strict';

var dialog = require('./dialog'),
    product = require('./pages/product'),
    util = require('./util'),
    youtube = require('./youtube'),
    _ = require('lodash'),
    variant = require('./pages/product/variant');

var makeUrl = function (url, source, productListID) {
    if (source) {
        url = util.appendParamToURL(url, 'source', source);
    }
    if (productListID) {
        url = util.appendParamToURL(url, 'productlistid', productListID);
    }
    return url;
};

var removeParam = function (url) {
    if (url.indexOf('?') !== -1) {
        return url.substring(0, url.indexOf('?'));
    } else {
        return url;
    }
};

var quickview = {
    init: function () {
        if (!this.exists()) {
            this.$container = $('<div/>').attr('id', 'QuickViewDialog').appendTo(document.body);
        }
        this.productLinks = $('#search-result-items .thumb-link').map(function (index, thumbLink) {
            return $(thumbLink).attr('href');
        });
    },

    setup: function (qvUrl) {
        product.initializeEvents();

        this.productLinkIndex = _(this.productLinks).findIndex(function (url) {
            return removeParam(url) === removeParam(qvUrl);
        });
    },

    swatchSlider: function (qv) {
        var $swatches = qv.find('ul.swatches.must-select'),
            $swatch = $swatches.find('li.selectable'),
            $slides;
        if ($swatches.hasClass('customizable-swatches')) {
            if ($(window).width() < '425') {
                $slides = 4;
            } else {
                $slides = 6;
            }
            
        } else {
            if ($(window).width() < '425') {
                $slides = 4;
            } else {
                $slides = 5;
            }
        }
        if ($swatch.length > $slides && !$swatches.hasClass('slick-initialized')) {
            $swatches.parents().find('.attribute.my-designs-line').last().not('.overflow').addClass('overflow');

            $swatches.slick({
                slidesToScroll: 1,
                slidesToShow: $slides,
                variableWidth: false,
                infinite: false,
                slide: 'li.selectable',
                responsive: [
                    {
                        breakpoint: 425,
                        settings: {
                            slidesToShow: 4,
                            slidesToScroll: 1
                        }
                    }
                ]
            });

        } else {
            if ($swatches.hasClass('slick-initialized') && !util.isMobile()) {
                $swatches.slick('unslick');
            }
        }
        
        setTimeout(function() {
            if ($swatches.hasClass('slick-initialized')) {
                var selected = $swatches.find('.selected[aria-hidden="true"]'),
                    currentSlide = selected.data('slick-index'),
                    perPage = $slides,
                    slides = $swatches.find('.slick-slide').length,
                    pages = slides - perPage,
                    i = (currentSlide > 0 ? currentSlide/perPage : 0),
                    selectedSlide = Math.ceil(i+i*i);

                if (selectedSlide > pages) {
                    selectedSlide = pages;
                }
                
                if (selected.length > 0) {
                    $swatches.slick('slickGoTo', selectedSlide);
                }
            }
        }, 500);
    },

    /**
     * @description show quick view dialog
     * @param {Object} options
     * @param {String} options.url - url of the product details
     * @param {String} options.source - source of the dialog to be appended to URL
     * @param {String} options.productlistid - to be appended to URL
     * @param {Function} options.callback - callback once the dialog is opened
     */
    show: function (options) {
        var url;
        if (!this.exists()) {
            this.init();
        }
        url = makeUrl(options.url, options.source, options.productlistid);
        var scrollPosition = 0;

        if ($('.shop-the-look').length > 0) {
            var $productTile = $('.stl-product.qv-active');
            $productTile.removeClass('qv-active');
            $productTile.html($productTile.html());
        }

        dialog.open({
            target: this.$container,
            url: url,
            options: {
                dialogClass: 'edit-product-modal',
                title: Resources.QUICK_VIEW,
                modal: true,
                width: 1134,
                height: 'auto',
                position: { my: "center", at: "center", of: window },
                open: function () {
                    this.setup(url);
                    if (typeof options.callback === 'function') { options.callback(); }
                    youtube();
                    if ('tagManager' in window) {
                        tagManager.init(dataLayer, {ns:"product"});
                    }
                    $(".noscroll").on('click', function (e) {
                        e.preventDefault();
                    });
                    if (window.Klarna && window.Klarna.OnsiteMessaging) {
                        window.Klarna.OnsiteMessaging.refresh();
                    }
                    
                    this.swatchSlider(this.$container);

                    $(window).resize(function () {
                        quickview.swatchSlider($('#QuickViewDialog'));
                    });

                    if($('.pt_product-details').length > 1) {
                        variant();
                    }

                    if (
                        this.$container.find('#reviewssnippet').html() == '' &&
                        window.pwr &&
                        window.PRConfig
                    ) {
                        if ($('.reviewssnippet').length > 1) {
                            var reviewHTML = $('#reviewssnippet').html();
                            this.$container.find('#reviewssnippet').html(reviewHTML);
                        } else {
                            window.pwr('render', window.PRConfig);
                        }
                    }

                    this.$container.find('.qv-product-price').empty();
                    this.$container.find('.qv-price').prependTo('.qv-product-price');
                    if (util.isMobile()) {
                        $('.promos-quickview').insertAfter('#QuickViewDialog .product-col-2');
                    }

                    $('#QuickViewDialog').find('.primary-image:first').each(function() {
                        if (this.complete) {
                            $(this).trigger('load');
                        }
                    }).on('load', function() {
                        $('.quickview-loading').remove();

                        $('#QuickViewDialog').dialog("option", "position", {
                            my: "center",
                            at: "center",
                            of: window
                        });

                        $('.edit-product-modal').scrollTop(0).css('opacity', '1');

                        if (util.isMobile()) {
                            $('#QuickViewDialog').prev().addClass('sticky');
                            scrollPosition = $(window).scrollTop();
                            $('body').css({
                                'top': '-${scrollPosition}px'
                            });
                            $('body, html').addClass('mobile-qv');
                        }

                        $('#QuickViewDialog').find('.pr-snippet-review-count').removeAttr('href');
                    });

                    if ($('#size-guide-wrap, #size-bg').length > 0) {
                        $('#size-guide-wrap, #size-bg').insertAfter('.edit-product-modal');
                    }
                }.bind(this),
                close: function () {
                    if (util.isMobile()) {
                        $('body').css({
                            'top': ''
                        });
                        $('body, html').removeClass('mobile-qv');
                        $(window).scrollTop(scrollPosition);
                    }
                    $(this).dialog('destroy').remove();
                    $('.quickview-loading').remove();
                    $('.size-guide-wrap').remove();
                    $('#wrapper').removeClass('wrapper-fixed');
                },
            },
        });
    },
    exists: function () {
        return this.$container && (this.$container.length > 0);
    }
};

module.exports = quickview;
