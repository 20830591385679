'use strict';
var carousel = require('./recprod');

/**
 * @private
 * @function
 * @description Addresses timing of carousel init due to Einstein being loaded dynamically after load
 */

var einsteinTabs = {
    init: function () {
        var targets = $('*[id^="cq_recomm_slot-"]');
        if (!targets.length) return;
        var config = {attributes: false, childList: true, characterData: false};
        targets.each(function () {
            var observer = new MutationObserver(function () {
				carousel.init();
                observer.disconnect();
            });
			observer.observe($(this)[0], config);
        });

        $('.tabbed-recommendations .config-tab').on('click touchstart', function (e) {
            e.preventDefault();
            var tabTog = $(this).data("toggle");

            // reinit carousel on tab click
            carousel.init()

            //Toggle tab link
            $(this).children().addClass('active').parent().siblings().find('.nav-link-rec').removeClass('active');

            //Toggle target tab
            $('div.' + tabTog).addClass('active').siblings().removeClass('active');
            $('.tabbed-recommendations .fj-rp-slider').slick('refresh');
        });
    }
};


module.exports = einsteinTabs;
