'use strict';

/**
 * @module int_amazonpay_sg/cartridge/js/amazon/amazonAccount.js
 */

/* eslint-disable no-undef */
module.exports = {
    /**
     * @function
     * @description init events for Amazon account button processing
     */
    init: function () {
        var $amazonPayButton = $('.js-amazon-pay-button');
        if ($amazonPayButton.length > 0) {
            var amazonButtonDataUrl = $amazonPayButton.data('amazon-pay-button-data-url');
            $.ajax(amazonButtonDataUrl)
                .done(function (amazonPayButtonData) {
                    amazon.Pay.renderButton('.js-amazon-pay-button', amazonPayButtonData);
                });
        }
    }
};
