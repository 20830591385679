'use strict';

var util = require('../util');

var EVENT = {
	PAGE_VIEW: 'Page View',
	VIEW_CONTENT: 'View Content',
	ADD_TO_CART: 'AddToCart',
	INITIATE_CHECKOUT: 'InitiateCheckout',
	PURCHASE: 'Purchase',
	COMPLETE_REGISTRATION: 'Complete Registration'
};

function trackEvent(event, eventData) {
	var data = eventData || {};
	// make the server call
	var url = util.appendParamToURL(Urls.conversionsTrackEvent, 'event', event);
	$.ajax({
		type: 'POST',
		dataType: 'json',
		cache: false,
		contentType: 'application/json',
		url: url,
		data: JSON.stringify(data)
	})
	.done(function () {
		// success
	})
	.fail(function (xhr, textStatus) {
		// failed
	});
}

var conversions = {
	/**
	 * @function
	 * @description Track "Page View" event
	 */
	trackPageView: function () {
		if (!SitePreferences.CONVERSIONS_TRACKING_ENABLED) {
			return;
		}

		var pageName = (pageContext && pageContext.title) ? pageContext.title : '';
		var eventData = {
			customData: {
				content_name: pageName
			}
		};
		trackEvent(EVENT.PAGE_VIEW, eventData);
	},
	/**
	 * @function
	 * @description Track "Content View" event
	 */
	trackViewContent: function (contentIds) {
		if (!SitePreferences.CONVERSIONS_TRACKING_ENABLED) {
			return;
		}

		var pageName = (pageContext && pageContext.title) ? pageContext.title : '';
		var eventData = {
			customData: {
				content_name: pageName,
				content_ids: contentIds
			}
		};
		trackEvent(EVENT.VIEW_CONTENT, eventData);
	},
	/**
	 * @function
	 * @description Track "AddToCart" event
	 */
	trackAddToCart: function (currency, value, contentIds) {
		if (!SitePreferences.CONVERSIONS_TRACKING_ENABLED) {
			return;
		}

		var eventData = {
			customData: {
				currency: currency,
				value: value,
				content_ids: contentIds
			}
		};
		trackEvent(EVENT.ADD_TO_CART, eventData);
	},
	/**
	 * @function
	 * @description Track "InitiateCheckout" event
	 */
	trackInitiateCheckout: function (numItems) {
		if (!SitePreferences.CONVERSIONS_TRACKING_ENABLED) {
			return;
		}

		var eventData = {
			customData: {
				num_items: numItems
			}
		};
		trackEvent(EVENT.INITIATE_CHECKOUT, eventData);
	},
	/**
	 * @function
	 * @description Track "Purchase" event
	 */
	trackPurchase: function (orderId, currency, value, contentIds) {
		if (!SitePreferences.CONVERSIONS_TRACKING_ENABLED) {
			return;
		}

		var eventData = {
			customData: {
				order_id: orderId,
				currency: currency,
				value: value,
				content_ids: contentIds
			}
		};
		trackEvent(EVENT.PURCHASE, eventData);
	},
	/**
	 * @function
	 * @description Track "Complete Registration" event
	 */
	trackCompleteRegistration: function (orderId, currency, value, contentIds) {
		if (!SitePreferences.CONVERSIONS_TRACKING_ENABLED) {
			return;
		}

		var pageName = (pageContext && pageContext.title) ? pageContext.title : '';
		var eventData = {
			customData: {
				content_name: pageName
			}
		};
		trackEvent(EVENT.COMPLETE_REGISTRATION, eventData);
	}
};

module.exports = conversions;
