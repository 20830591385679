'use strict';

var util = require('../../util');

/**
 * @description Creates product recommendation carousel
 **/
module.exports = function () {
    var $carousel = $('#carousel-recommendations');
    $carousel.slick();

    $(document).on('mouseleave', '.swatch-list', function () {
        // Restore current thumb image
        var $tile = $(this).closest('.product-tile'),
            $thumb = $tile.find('.product-image .thumb-link img').eq(0),
            badgePlp = $tile.children('img').filter(':first'),
            data = $thumb.data('current'),
            $selectedVariantSwatch = $(this).find('.swatch.selected img'),
            selectedVariantBadge = $selectedVariantSwatch.data('badgeurl'),
            selectedVariantTextBadge = $selectedVariantSwatch.data('badgetext'),
            currentBadge = $tile.find('.product-badge-plp').attr('src'),
            bage = $selectedVariantSwatch.data('badgeurl'),
            badgeStyle = $selectedVariantSwatch.data('badgetextstyle'),
            $mocheck = $tile.find('.members-only-check').eq(0),
            $textBadge = $tile.find('.product-badge'),
			mainsrc = $selectedVariantSwatch ? $selectedVariantSwatch.data('thumb').src : data.src;
            if (util.getViewport() > 1680) {
                mainsrc = $selectedVariantSwatch ? $selectedVariantSwatch.data('thumb').srcxl : data.srcxl;
            } else if (util.getViewport() > 1160 && util.getViewport() < 1679) {
                mainsrc = $selectedVariantSwatch ? $selectedVariantSwatch.data('thumb').srclg : data.srclg;
            } else {
                mainsrc = $selectedVariantSwatch ? $selectedVariantSwatch.data('thumb').src : data.src;
            }
        var isMOTile = false;
        if ($selectedVariantSwatch.data('membersonly')) {
            isMOTile = true;
        }
        if (isMOTile) {
            if ($mocheck.hasClass('unlocked')) {
                bage = $selectedVariantSwatch.data('memunlocked');
            } else {
                bage = $selectedVariantSwatch.data('memlocked');
            }
        }
        if (data && data.hasOwnProperty('src')) {
            $thumb.attr({
                src: data.src,
                alt: data.alt,
				srcset: mainsrc,
                title: data.title
            });
        }

        if (selectedVariantBadge === undefined) {
            badgePlp.replaceWith('');
        } else {
            if (currentBadge !== selectedVariantBadge) {
                $tile.find('object').remove();
                $tile.find('.product-badge-plp').remove();
                $tile.prepend($('<object style="position: absolute" type="image/svg+xml" data="' + selectedVariantBadge + '" width="0" height="0" border="0"></object><img src="' + selectedVariantBadge + '" alt="" class="product-badge-plp">'));
            }
        }

        if (selectedVariantTextBadge) {
            if ($textBadge.length > 0) {
                if (selectedVariantTextBadge !== $textBadge.text().trim()) {
                    // replace text badge
                    $textBadge.removeClass().addClass('product-badge ' + badgeStyle);
                    $textBadge.html('<span>' + selectedVariantTextBadge + '</span>');
                }
            } else {
                // insert new text badge
                $tile.find('.thumb-link').append('<span class="product-badge ' + badgeStyle + '"><span>' + selectedVariantTextBadge + '</span></span>');
            }
        } else {
            if ($textBadge.length > 0) {
                $textBadge.remove();
            }
        }

        if ($selectedVariantSwatch.data('membersonly')) {
            $tile.addClass('members-only');
            if ($mocheck.hasClass('unlocked')) {
                $tile.addClass('unlocked');
            }
        } else {
            $tile.removeClass('members-only');
        }
    });

    $(document).on('click touchstart', '.swatch-list .swatch', function (e) {
        if (!$(this).closest('.product-listing').length) { return; }
        e.preventDefault();
        if ($(this).hasClass('selected')) { return; }

        var $tile = $(this).closest('.product-tile');
        var $mocheck = $tile.find('.members-only-check').eq(0);
        $(this).closest('.swatch-list').find('.swatch.selected').removeClass('selected');
        $(this).addClass('selected');
        $tile.find('.thumb-link').attr('href', $(this).attr('href'));
        $tile.find('.name-link').attr('href', $(this).attr('href'));

        var $selectedVariantSwatch = $(this).children('img').filter(':first');
        var data = $selectedVariantSwatch.data('thumb');
        var bage = $selectedVariantSwatch.data('badgeurl');
        var isMOTile = false;
        if ($selectedVariantSwatch.data('membersonly')) {
            isMOTile = true;
        }
        if (isMOTile) {
            if ($mocheck.hasClass('unlocked')) {
                bage = $selectedVariantSwatch.data('memunlocked');
            } else {
                bage = $selectedVariantSwatch.data('memlocked');
            }
        }
        var badgePlp = $tile.children('img').filter(':first');
        var $thumb = $tile.find('.product-image .thumb-link img').eq(0);
        var currentAttrs = {
            src: data.src,
            alt: data.alt,
            title: data.title,
            bage: $('<object style="position: absolute" type="image/svg+xml" data="' + bage + '" width="0" height="0" border="0"></object><img src="' + bage + '" alt="" class="product-badge-plp">')
        };
        $thumb.attr(currentAttrs);
        $thumb.data('current', currentAttrs);
        if (typeof(bage) != 'undefined') {
            if (badgePlp.length) {
                badgePlp.prev().remove();
                badgePlp.replaceWith($('<object style="position: absolute" type="image/svg+xml" data="' + bage + '" width="0" height="0" border="0"></object><img src="' + bage + '" alt="" class="product-badge-plp">'));
            } else {
                $tile.find('object').remove()
                $tile.prepend($('<object style="position: absolute" type="image/svg+xml" data="' + bage + '" width="0" height="0" border="0"></object><img src="' + bage + '" alt="" class="product-badge-plp">'));
            }
        } else {
            badgePlp.replaceWith('');
        }

        var $textBadge = $tile.find('.product-badge');
        var thumbTextBadge = $selectedVariantSwatch.data('badgetext');
        var badgeStyle = $selectedVariantSwatch.data('badgetextstyle');

        if (thumbTextBadge) {
            if ($textBadge.length > 0) {
                if (thumbTextBadge !== $textBadge.text().trim()) {
                    // replace text badge
                    $textBadge.removeClass().addClass('product-badge ' + badgeStyle);
                    $textBadge.html('<span>' + thumbTextBadge + '</span>');
                }
            } else {
                // insert new text badge
                $tile.find('.thumb-link').append('<span class="product-badge ' + badgeStyle + '"><span>' + thumbTextBadge + '</span></span>');
            }
        } else if ($textBadge.length > 0) {
            $textBadge.remove();
        }

        if ($selectedVariantSwatch.data('membersonly')) {
            $tile.addClass('members-only');
            if ($mocheck.hasClass('unlocked')) {
                $tile.addClass('unlocked');
            }
        } else {
            $tile.removeClass('members-only');
        }
    });
	$(document).on('mouseenter', '.swatch-list .swatch', function () {
        // get current thumb details
        var $tile = $(this).closest('.product-tile'),
            $mocheck = $tile.find('.members-only-check').eq(0),
            $thumb = $tile.find('.product-image .thumb-link img').eq(0),
            $selectedVariantSwatch = $(this).children('img').filter(':first'),
            currentBadge = $tile.find('.product-badge-plp').attr('src'),
            data = $selectedVariantSwatch.data('thumb'),
            bage = $selectedVariantSwatch.data('badgeurl'),
            badgePlp = $tile.children('img').filter(':first'),
            $textBadge = $tile.find('.product-badge'),
            badgeStyle = $selectedVariantSwatch.data('badgetextstyle'),
            thumbTextBadge = $selectedVariantSwatch.data('badgetext'),
            current = $thumb.data('current'),
			mainsrc = data.src;
            if (util.getViewport() > 1680) {
                mainsrc = data.srcxl;
            } else if (util.getViewport() > 1160 && util.getViewport() < 1679) {
                mainsrc = data.srclg;
            } else {
                mainsrc = data.src;
            }
        var isMOTile = false;

        if ($selectedVariantSwatch.data('membersonly')) {
            isMOTile = true;
        }
        if (isMOTile) {
            if ($mocheck.hasClass('unlocked')) {
                bage = $selectedVariantSwatch.data('memunlocked');
            } else {
                bage = $selectedVariantSwatch.data('memlocked');
            }
        }

        // If this is the first time, then record the current img
        if (!current) {
            $thumb.data('current', {
                src: $thumb[0].src,
                alt: $thumb[0].alt,
                title: $thumb[0].title,
                bage: badgePlp
            });
        } else {
            if (typeof(current.bage) == 'undefined' && badgePlp.length > 0) {
                current.bage = badgePlp;
                $thumb.data('current', current);
            }
        }

        if (typeof(bage) != 'undefined') {
            if (badgePlp.length) {
                if (currentBadge !== bage) {
                    badgePlp.prev().remove();
                    badgePlp.replaceWith($('<object style="position: absolute" type="image/svg+xml" data="' + bage + '" width="0" height="0" border="0"></object><img src="' + bage + '" alt="" class="product-badge-plp">'));
                }
            } else {
                $tile.find('object').remove();
                $tile.prepend($('<object style="position: absolute" type="image/svg+xml" data="' + bage + '" width="0" height="0" border="0"></object><img src="' + bage + '" alt="" class="product-badge-plp">'));
            }
        } else {
            badgePlp.replaceWith('');
        }

        if (thumbTextBadge) {
            if ($textBadge.length > 0) {
                if (thumbTextBadge !== $textBadge.text().trim()) {
                    // replace text badge
                    $textBadge.removeClass().addClass('product-badge ' + badgeStyle);
                    $textBadge.html('<span>' + thumbTextBadge + '</span>');
                }
            } else {
                // insert new text badge
                $tile.find('.thumb-link').append('<span class="product-badge ' + badgeStyle + '"><span>' + thumbTextBadge + '</span></span>');
            }
        } else if ($textBadge.length > 0) {
            $textBadge.remove();
        }

        if ($selectedVariantSwatch.data('membersonly')) {
            $tile.addClass('members-only');
            if ($mocheck.hasClass('unlocked')) {
                $tile.addClass('unlocked');
            } else {
                $selectedVariantSwatch.addClass('locked');
            }
        } else {
            $tile.removeClass('members-only');
        }


        // Set the tile image to the values provided on the swatch data attributes
        $thumb.attr({
            src: data.src,
            alt: data.alt,
			srcset: mainsrc,
            title: data.title
        });
    });

    $('.swatch.selected').trigger('mouseenter', [{isInitialize: true}]);
};
