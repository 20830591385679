'use strict';

var util = require('../util');

function updateCategorySection(element) {
    var checked = element.is(':checked');
    if (!checked && $('#FIInterests input[type="checkbox"]').length > 0) {
        $('#FIInterests input[type="checkbox"]').prop('checked', checked);
        $('#FIInterests input[type="checkbox"]').attr('checked', checked);
        $('#categoriesSection').removeClass('disabledbutton');
        $('#categoriesSection').addClass('disabledbutton');
    } else {
        $('#categoriesSection').removeClass('disabledbutton');
    }
}

var accountfj = {
    init: function () {
        var $slideItems = $('.dash-whatsnew').find('.grid-tile').length;
        var $slidesToShow = 1;
        $("#RegistrationForm").on('click', '#password-show', function () {
            $(this).toggleClass("eye-show");
            var input = $(".password-form");
            if (input.attr("type") === "password") {
                input.attr("type", "text");
            } else {
                input.attr("type", "password");
            }
        });

        $(".sizing-section").on('click', '#stylepreferencemens, #stylepreferencewomens', function () {
            $('.sizing-section').filter('.sizing-section').attr('class', 'sizing-section')
            .end().not('.sizing-section').removeClass();
            $(".sizing-section").addClass($(this).data('sizetoggle'));
        });

        $('input[name*="_addtoemaillist"]').change(function () {
            updateCategorySection($(this));
        });

        updateCategorySection($('input[name*="_addtoemaillist"]'));

        if ($('.section-climate input[type="checkbox"]').is(':checked') && $('.section-golftype input[type="radio"]').is(':checked')) {
            $('.acc-nav-golfpref').addClass('tab-on');
        }

        if ($('#dwfrm_profile_customer_addtoemaillist').is(':checked') || $('#FIInterests input[type="checkbox"]').is(':checked')) {
            $('.acc-nav-commpref').addClass('tab-on');
        }

        var regOpen = util.getQueryStringParams(window.location.search.substr(1));
        if (regOpen.register == 'true') {
            $('#register-toggler.register').addClass('active');
        } else {
            $('#login-toggler.login').addClass('active');
        }

        function initProgressBar() {
            var $slider = $('.dash-whatsnew');
            var $progressBar = $('.progressDash');
            $slider.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
                var calc = ((nextSlide) / (slick.slideCount - 1)) * 100;
                //$progressBarLabel.css('left', calc + '%').attr('aria-valuenow', calc);
                $progressBar.css('background-size', calc + '% 100%')
            });

            $slider.slick({
                infinite: false,
                slidesToShow: 1,
                slidesToScroll: 1,
                mobileFirst: true,
                responsive: [
                    {
                        breakpoint: 768,
                        settings: "unslick"
                    }
                ]
            });
        }

        initProgressBar();

        function countSelected() {
            //var toReturn = true;
            var count = 0;
            $('.sizing-prefs select').each(function () {
                var option = $('option:selected', this).attr('selected');
                if (option) {
                    count++
                };
            });
            if (count == 8) {
                // all select boxes has selected option
                $('.acc-nav-shoppref').addClass('tab-on');
            }
        };
        countSelected();
    }
};

module.exports = accountfj;