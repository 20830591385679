'use strict';

var util = require('./util'),
	bonusProductsView = require('./bonus-products-view');

var isAccessibilityMode = function () {
	return $('body').hasClass('un-assistive');
};

var timer = {
	id: null,
	clear: function () {
		if (this.id) {
			window.clearTimeout(this.id);
			delete this.id;
		}
	},
	start: function (duration, callback) {
		this.id = setTimeout(callback, duration);
	}
};

var minicart = {
	init: function () {
		this.$el = $('#mini-cart');
		this.$content = this.$el.find('.mini-cart-content');

		$('.mini-cart-product').eq(0).find('.mini-cart-toggle').addClass('fa-caret-down');

		// events
		this.$el.find('.mini-cart-total').on('mouseenter touchstart', function () {
			if (this.$content.not(':visible')) {
				$('.mini-cart-content').addClass('cart-show');
			}
		}.bind(this));

		this.$content.on('mouseenter', function () {
			timer.clear();
		});

		// events
		this.$el.find('.mini-cart-total, .mini-cart-link').on('click touchstart', function (e) {
			var isCartEmpty = $('.mini-cart-link.mini-cart-empty').length > 0;
			if (util.getViewport() < 880 && $('body.enable-slideout-cart').length && !isCartEmpty) {
				e.preventDefault();
			}
		});

		$('.mini-cart-close').on('click touchstart', function (e) {
            e.preventDefault();
			$('.mini-cart-content').removeClass('cart-show');
			return false;
		});

		$('#mini-cart').on('mouseleave', function () {
			$('.mini-cart-content').removeClass('cart-show').removeAttr('style');
		});
	},
	/**
	 * @function
	 * @description Shows the given content in the mini cart
	 * @param {String} A HTML string with the content which will be shown
	 */
	show: function (html, needSlide) {
		this.$el.html(html);
		var showBonusTimeOut = 0;

		if (util.getViewport() > 767) {
			util.scrollBrowser(0);
			showBonusTimeOut = 500;
		}
		this.init();
		if (needSlide || typeof needSlide == 'undefined') {
			this.slide();
			setTimeout(function () {
				bonusProductsView.loadBonusOption();
			}, showBonusTimeOut);
		}
	},
	/**
	 * @function
	 * @description Slides down and show the contents of the mini cart
	 */
	slide: function () {
		timer.clear();
		//we need to position the content right aligned with the link
		//var position = ($('#mini-cart').offset().left + $('#mini-cart').outerWidth()) - this.$content.width();
		//this.$content.css('left', position);
		// show the item
		if ($('body.enable-slideout-cart').length) {
			$('.mini-cart-content').addClass('cart-show').delay(5000).queue(function () {
                if (!isAccessibilityMode()) {
                    $(this).removeClass('cart-show');
                }
			});
		} else {
			this.$content.slideDown('slow');
			// after a time out automatically close it
			if (!isAccessibilityMode()) {
				timer.start(6000, this.close.bind(this));
			}
		}
	},
	/**
	 * @function
	 * @description Closes the mini cart with given delay
	 * @param {Number} delay The delay in milliseconds
	 */
	close: function (delay) {
		timer.clear();
		this.$content.slideUp(delay);
	}
};

module.exports = minicart;
