'use strict';

var util = require('./util');

var toggleAriaExpanded = function (el) {
	var currValue = $(el).attr('aria-expanded');
	if (currValue == 'true') {
		currValue = 'false';
	} else {
		currValue = 'true';
	}
	$(el).attr('aria-expanded', currValue);
}

var toogleMainMenu = function () {

// Burger menu-active open/close on click.
	if ($('.js-hamburguer-mobile').hasClass('active')) {
		if ($('.mobile-hamburger-toggle.active').length > 0) {
			$('#wrapper').removeClass('menu-active');
			$('.js-header-mobile-close').hide();
			$('.mini-cart-content').removeClass('cart-show');
			$('.user-info').removeClass('is-active');
			$('.header-dropdown').removeClass('active');
		};
	} else {
		$('#wrapper').toggleClass('menu-active');
		$('.js-header-mobile-close').hide();
		$('.mini-cart-content').removeClass('cart-show');
		$('.user-info').removeClass('is-active');
		$('.header-dropdown').removeClass('active');
	}
}

var headermenu = {
	init: function () {
		// main menu toggle
		$('.menu-toggle').on('click', function () {
			toogleMainMenu.apply(this);
		}).on('keydown', function (e) {
			var key = e.keyCode ? e.keyCode : e.which;
			var KEY_CODE_ENTER = 13;
			if (KEY_CODE_ENTER == key) {
				toogleMainMenu.apply(this);
			}
		});

        var expandSubMenu = function (e) {
            e.preventDefault();
            if ($(this).is('.menu-item-toggle')) {
                // Ctrl-Click main categories open in new browser
                if ($(this).hasClass('active') || window.innerWidth > 1199) {
                    if (e.ctrlKey) {
                        window.open($(this).attr('href'), '_blank');
                    } else {
                        document.location.href = $(this).attr('href');
                        return;
                    }
                }
            }
            var $parentLi = $(e.target).closest('li');
            $parentLi.siblings('li').removeClass('active').find('.menu-item-toggle').removeClass('fa-chevron-up active').addClass('fa-chevron-right');
            $parentLi.toggleClass('active');
            if ($(this).is('.menu-item-toggle')) {
                $(e.target).toggleClass('fa-chevron-right fa-chevron-up active');
			} else {
                $(e.target).siblings('.menu-item-toggle').toggleClass('fa-chevron-right fa-chevron-up active');
			}
            toggleAriaExpanded(this);
        };

		var onHover = function (e) {
			$('.level-2').delay(300).hide(0);
			$('.level-1 li').removeClass('active open');
			var $this = $(this).hasClass('menu-item-toggle') ? $(this).parent('li') : $(this);
			var firstFocusable = $this.find('a').first()[0];
			// check if focus is in current area, if not trigger focus on first element
			// trigger only on usual hover, if focus is out of object
			if (e.type != 'focusin' && firstFocusable && (!document.activeElement || !$.contains($this[0], document.activeElement))) {
				firstFocusable.needFocus = true;
				// added check and return after focus triggered, it can fail
				if (!firstFocusable.needFocus) return;
			}
			// set flag to mark focus
			this.needFocus = false;
			var $elements = $this.find('.megamenu-content').children('ul, div');
			$this.find('.level-2')
				.stop(true, true)
				.delay(150)
				.queue(function (next) {
					if ($this.hasClass('open') === false) {
						// set opacity of content inside menu to 0 before recursiveReveal()
						$elements.css('opacity', '0');
						next();
					}
				})
				.slideDown(function () {
					if ($this.hasClass('open') === false) {
						$this.addClass('active open');
						util.recursiveReveal($elements);

						var $slider = $this.find('.js-subcategory-banner__collection');
						var $sliderElements = $slider.find('.js-subcategory-banner');

						if ($sliderElements.length > 1 && !$slider.hasClass('slick-initialized') && $(window).width() < 1570) {
							$slider.on('afterChange', function (e, slick, currentSlide) {
								if (currentSlide >= 1) {
									$slider.find('.slick-prev').addClass('active')
								} else {
									$slider.find('.slick-prev').removeClass('active')
								}

								if (currentSlide === slick.$slides.length) {
									$slider.find('.slick-next').addClass('hidden')
								} else {
									$slider.find('.slick-next').removeClass('hidden')
								}
							})

							$slider.slick({
								slidesToShow: 1,
								slidesToScroll: 1,
								dots: false,
								arrows: true,
								variableWidth: true,
								swipeToSlide: true,
								infinite: false
							})
						}
					}
				});
		};
		var unHover = function (e) {
			// prevent unhover if we are no moving from that tab
			if ($(e.relatedTarget).parents('li.open').length > 0) {
				return;
			}
			var $this = $(this).hasClass('menu-item-toggle') ? $(this).parent('li') : $(this);
			$this.find('.level-2')
				.stop(true, true)
				.delay(200)
				.fadeOut('fast', function () {
					$this.removeClass('active open');
					$this.find('.slick-initialized').slick('destroy')
				});
		};
		var keyPress = function (e) {
			// prevent unhover if we are no moving from that tab
			var key = e.keyCode ? e.keyCode : e.which,
				KEY_CODE_SPACE = 32;
			if (KEY_CODE_SPACE == key) {
				e.preventDefault();
				if (!$(this).parents('li').hasClass('open')) {
					$(this).trigger('mouseenter');
				} else {
					$(this).trigger('mouseleave');
				}
			}
		};
		var unHoverPanelButton = function () {
			$(this).unbind();
			$(this).on('keydown', function (e) {
				// Shift + Tab key
				if (e.shiftKey && e.keyCode == 9 && !$(this).prev().hasClass('button')) {
                    fullpage_api.moveSectionUp();
                    $(this).parent().last().focus();
                // Tab key
				} else if (e.keyCode == 9 && !$(this).hasClass('fj-rp-slider-arrow') && !$(this).next().hasClass('button')) {
                    fullpage_api.moveSectionDown();
                }
            });
		};
		$('.menu-category li .menu-item-toggle, .menu-category li .menu-item-toggle-nav').on('click', expandSubMenu);
		if (window.innerWidth > 879) {
			$('.menu-category.level-1').children('li').hover(onHover, unHover);
			$('.menu-category.level-1').children('li').find('.menu-item-toggle').on('focus', onHover);
			$('.menu-category.level-1').children('li').find('.menu-item-toggle').on('blur', unHover);
			$('.fj-main-slide-content-buttons a').on('focus', unHoverPanelButton);

			// Need a slight delay for the recommended carousel to render before attaching event
			setTimeout(function () {
                $('.fj-recommended-products .fj-rp-slider-wrapper').find('a:first').on('focus', unHoverPanelButton);
            }, 3000);
			$('.menu-category li .menu-item-toggle').on('keydown', keyPress);
		}
		if (!$('#wrapper.pt_checkout').length) {
            $(window).on('scroll', function () {
                if ($(window).scrollTop() >= 61) {
                    $('body').addClass('sticky-nav');
                    $('#top-banner').addClass('sticky');
                } else {
                    $('body').removeClass('sticky-nav');
                    $('#top-banner').removeClass('sticky');
                }
            });
		}

		$('.js-subcategories__name__toggle-btn').on('click', function () {
			var $btn = $(this);
			$btn.toggleClass('collapsed');
			$btn.parents('.js-subcategories').find('.js-subcategories__list__wrapper').toggleClass('collapsed');
		});
	}
};

module.exports = headermenu;
