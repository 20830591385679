'use strict';
var util = require('../../util'),
    imagesLoaded = require('imagesloaded'),
	validator = require('../../validator'),
    productTile = require('../../product-tile');
const product = require('../product');

var homepage = {
    init: function () {
        // jscs:disable
        /***** Get The Stack Cranking *****/
        if ( typeof FJ_Homepage == 'undefined' ) {
            //object for organizing code
            var	FJ_Homepage = {
                breakpoint: util.isMobile() ? $(window).width() + 1 : 880,
                mode: '',

                /**
                 * debounce - Use this like $( window ).resize( canvas.debounce( function(){}, 500 ) );
                 *
                 * @param function 	fn 		Pass in the function to call after the delay
                 * @param integer 	delay 	The time (in miliseconds) to delay the function from running
                 *
                 * @return function 		returns a function which calls passed in function when the delay has run out
                 */
                debounce: function( fn, delay ) {
                    var timer = null;

                    return function() {
                        var context = this,
                            args = arguments;

                        clearTimeout( timer );

                        timer = setTimeout( function() {
                            fn.apply( context, args );
                        }, delay );
                    };
                },

                init: function() {
                    FJ_Homepage.reflowMainSlider();
                    if (window.location.hash) {
                        var hash = window.location.hash;
                        window.location.hash = '';
                        window.location.hash = hash;
                    }
					validator.init();
                },

                reflowMainSlider: function() {
                    if ( $( window ).width() >= FJ_Homepage.breakpoint ) {
                        // mobile-to-desktop transition
                        if ( FJ_Homepage.mode !== 'desktop' ) {
                            // Destroy slick (if already created)
                            if ( $( '.pd-spotlight-tiles-container.slick-initialized' ).length > 0 ) {
                                $( '.pd-spotlight-tiles-container.slick-initialized' ).slick( 'unslick' );
                            }
                        }

                        // set desktop mode
                        FJ_Homepage.mode = 'desktop';
                    } else {
                        // desktop-to-mobile transition
                        if ( FJ_Homepage.mode !== 'mobile' ) {

                            $( '.pd-spotlight-tiles-container' ).slick( {
                                arrows: true,
                                dots: false,
                                infinite: true,
                                slide: '.pd-spotlight-tiles',
                                slidesToShow: 1,
                                centerMode: true,
                                slidesToScroll: 1,
                                touchMove: true
                            } );
                        }

                        // set mobile mode
                        FJ_Homepage.mode = 'mobile';
                    }
                }
            };

            $(window).on( 'resize', FJ_Homepage.debounce( function() {
                        FJ_Homepage.reflowMainSlider();
                        FJ_Homepage.breakpoint = util.isMobile() ? $(window).width() + 1 : 880;
                    }, 50 )
                );

            $(document).ready(function() {
                if (location.hash) {
                    setTimeout(function() {
                        window.scrollTo(0, 0);
                        FJ_Homepage.init();
                    }, 1);
                } else {
                    FJ_Homepage.init();
                }
                productTile.init();
            });
        }
    }
};

module.exports = homepage;
