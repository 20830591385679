'use strict';

require('../../touch')(); //touch events

var dialog = require('../../dialog'),
	util = require('../../util');

var event360view = function () {
    $('.view-360-button').on('click', function (e) {
        e.preventDefault();
        if ($(this).data('sprite').length) {
            var imageSize = $(this).closest('.product-image-container').find('.primary-image').eq(0).width();
            var $spriteContainer = $('.spritespin.desktop');
            if (!$spriteContainer.hasClass('hidden')) {
                $spriteContainer.addClass('hidden');
            } else {
                $spriteContainer.removeClass('hidden');
                loadSprite360($(this).data('sprite'), imageSize, 'desktop');
            }
        }
    });
};

	/**
 * @description Sets the main image attributes and the href for the surrounding <a> tag
 * @param {Object} atts Object with url, alt, title and hires properties
 */
var setMainImage = function (atts) {
	$('#pdpMain .product-primary-image .slick-current .primary-image').attr({
		src: atts.url,
		alt: atts.alt,
		title: atts.title
	});
	if (!dialog.isActive() && !util.isMobile()) {
		$('#pdpMain .main-image').attr('href', atts.hires);
	}
};

/**
 * @description Replaces the images in the image container, for eg. when a different color was clicked.
 */
var replaceImages = function () {
    var $newImages = $('#update-images'),
        $imageContainer;
    if ($('.product-images').length) {
        $imageContainer = $('#pdpMain .product-images');
    } else {
        $imageContainer = $('#pdpMain .product-image-container');
    }

    if ($newImages.length === 0) {
        return;
    }

	$imageContainer.html($newImages.html());
	$newImages.remove();
};

var thumbAdjustment = function () {
	var thumbPos = $('.product-image-container').height() - $('.product-primary-image').height() + 16;
	$('.thumbnails-container').css('bottom', thumbPos);
};

/**
 * @description Creates a carousel out of the thumbnails that are setup for each product
 */
var setupCarousel = function () {
	var $mainImageSlider = $('.regular'),
        $thumbnailsSlider = $('.product-thumbnails .carousel'),
        $psThumbnailsSlider = $('.product-thumbnails.product-set-thumbnails .carousel'),
		$thumbnailsSliderProductSet = $('.product-image-container.product-set .product-thumbnails .carousel'),
        $quickview = $mainImageSlider.hasClass('qv-true'),
        $infinite,
        $dots;

    if ($quickview) {
        $infinite = true;
        $dots = false;
    } else {
        $infinite = false;
        if (util.isMobile()) {
            $dots = true;
        } else {
            $dots = false;
        }
    }

    $mainImageSlider.not('.slick-initialized').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        dots: $dots,
        infinite: $infinite,
        fade: false,
        responsive: [
            {
                breakpoint: 880,
                settings: {
                    dots: $dots,
                }
            }
        ]
	});

	// Setup the carousel of thumbnails near the main image
	$thumbnailsSlider.on('init', function () {
		$('.product-thumbnails .carousel .slick-slide.slick-current').addClass('is-active');
		thumbAdjustment();
	});
	$thumbnailsSlider.not('.slick-initialized').slick({
		vertical: false,
		infinite: false,
		slidesToShow: $thumbnailsSliderProductSet.length > 0 ? 4 : 6,
		slidesToScroll: 1,
		swipe: true,
		arrows: true,
		dots: false,
		touchMove: true,
		focusOnSelect: false,
		responsive: [
			{
				breakpoint: 1199,
				settings: {
					slidesToShow: $thumbnailsSliderProductSet.length > 0 ? 4 : 5,
					slidesToScroll: 1,
					infinite: false,
					arrows: true
				}
			},
			{
				breakpoint: 1000,
				settings: {
					slidesToShow: $thumbnailsSliderProductSet.length > 0 ? 4 : 5,
					slidesToScroll: 1,
					infinite: false,
					arrows: true
				}
			}
		]
	});

    if ($psThumbnailsSlider.length === 0) {
        $mainImageSlider
        .on('beforeChange', function () {
            var $spriteContainer = $('.spritespin.desktop');
            if (!$spriteContainer.hasClass('hidden')) {
                $spriteContainer.addClass('hidden');
            }
        })
        .on('afterChange', function (event, slick, currentSlide) {
            $thumbnailsSlider.slick('slickGoTo', currentSlide);
            var currentNavSlideElem = '.product-thumbnails .carousel .slick-slide[data-slick-index="' + currentSlide + '"]';
            $('.product-thumbnails .carousel .slick-slide.is-active').removeClass('is-active');
            $(currentNavSlideElem).addClass('is-active');
        });

        $thumbnailsSlider.on('click', '.slick-slide', function (event) {
            event.preventDefault();
            var goToSingleSlide = $(this).data('slick-index');
            $mainImageSlider.slick('slickGoTo', goToSingleSlide);
        });
    } else {
        $mainImageSlider
        .on('beforeChange', function () {
            var $spriteContainer = $('.spritespin.desktop');
            if (!$spriteContainer.hasClass('hidden')) {
                $spriteContainer.addClass('hidden');
            }
        });
    }

	// Carousel for Mobile
	var $pdpMobileSlider = $('.pdp-mobile-slider');
	$pdpMobileSlider.on('init', function () {
		// refresh on load first image
		$pdpMobileSlider.find('.slick-active img').on('load', function () {
			$('.pdp-mobile-slider').resize();
		});

		// init 360view functionality
		event360view();

		// show slider and hide preloader
		$pdpMobileSlider.removeClass('hide-on-load');
		$('.mobile-slider-loader').hide();
	});

	$pdpMobileSlider.slick({
		lazyload: 'progressive',
		arrows: false,
		dots: true,
		infinite: true,
		touchThreshold: 5,
		appendDots: $('.pdp-mobile-slider-dots')
	});

    event360view();

};

var loadSprite360 = function (url, imageSize, viewport) {
    var $viewport = (viewport === 'mobile' ? $('.spritespin.mobile') : $('.spritespin.desktop'));
    $viewport.spritespin({
        // Set a single image url as source
        source: url,
        // Define the size of the display.
        width: imageSize,
        height: imageSize,
        // The total number of frames to show
        frames: SitePreferences.SPRITE_360_FRAMES,
        // The number of frames in one row of the sprite sheet
        framesX: SitePreferences.SPRITE_360_FRAMES_X,
        // Time in ms between updates. 40 is exactly 25 FPS
        frameTime: SitePreferences.SPRITE_360_FRAMETIME,
        // Auto-rotate on load
        animate: SitePreferences.SPRITE_360_ANIMATE,
        sense: -1
    });
};
/**
 * @description by default, this function sets up zoom and event handler for thumbnail click
 **/
module.exports = function () {
	setupCarousel();
	if (dialog.isActive() || util.isMobile()) {
		$('#pdpMain .main-image').removeAttr('href');
	}

	//prevent finger scroll for swipeup/down
    $('#pdpMain').on('touchstart, click', '.product-primary-image a:not(.plus-image-link)', function (e) {e.preventDefault();});

    $('#pdpMain').on('click', '.primary-image', function (e) {
        e.preventDefault();
        if ($(this).hasClass('ps-primary-image') || $(this).parents().find('#QuickViewDialog').length > 0) {
            return false;
        }
        var $zoomContent = $('#js-zoom-content');
        $('body').addClass('fixedPosition');
        $zoomContent.dialog({
            dialogClass: 'zoom-image-dialog',
            modal: true,
            draggable: false,
            resizable: false,
            width: $(window).width(),
            height: $(window).height(),
            left: 0,
            top: 0,
            open: function () {
                $('#js-zoom-content').removeClass('hidden');
            },
            close: function () {
				$('#js-zoom-content').addClass('hidden');
                $(this).dialog('destroy');
                $('body').removeClass('fixedPosition');
            }
        });
    });
};

module.exports.setMainImage = setMainImage;
module.exports.replaceImages = replaceImages;
module.exports.setupCarousel = setupCarousel;
