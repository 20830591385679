'use strict';

function slider() {
    var $slider = $('.explore-slider');

    if ($slider.length && !$slider.hasClass('slick-slider')) {
        var options = {
            slidesToShow: 3,
            slidesToScroll: 1,
            slide: '.explore__slide',
            vertical: false,
            infinite: false,
            swipe: true,
            arrows: true,
            dots: false,
            touchMove: true,
            focusOnSelect: false,
            arrows: true,
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 1,
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        $slider.slick(options)
    }
}

exports.init = function () {
    slider();
};
