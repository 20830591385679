module.exports.init = function () {
	$(document).ready(function () {
		$('.experience-myjoysSlider').slick({
			mobileFirst: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			slide: '.experience-assets-producttile',
			vertical: false,
			infinite: false,
			swipe: true,
			arrows: true,
			dots: false,
			touchMove: true,
			centerMode: true,
			centerPadding:'50px',
			responsive: [
				{
					breakpoint: 880,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 1,
						centerMode: false,
						infinite: false
					}
				}
			]
		});
	});


}
