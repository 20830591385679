'use strict';

var ontour = {
    init: function () {
    	// jscs:disable
		if (typeof FJ_OnTour == 'undefined') {
			var FJ_OnTour = {
				debounce: function (fn, delay) {
					var timer = null;

					return function () {
						var context = this,
						args = arguments;

						clearTimeout(timer);

						timer = setTimeout(function () {
							fn.apply(context, args);
						}, delay);
					};
				},

				init: function () {
					FJ_OnTour.responsiveSlider();
					FJ_OnTour.hotSpotHover();
				},

				responsiveSlider: function () {
					if ($(window).width() > 880) {
						if ($(".fj-on-tour-spotlights.slick-initialized").length > 0) {
							$(".fj-on-tour-spotlights.slick-initialized").slick("unslick");
							$(".fj-on-tour-spotlights .fj-on-tour-spotlight").addClass('widthFix');
						}
					} else {
						if ($('.fj-on-tour-spotlights.slick-initialized').length <= 0) {
							$('.fj-on-tour-spotlights').slick({
								arrows: true,
								dots: true,
								infinite: false,
								autoplay: SitePreferences.ON_TOUR_SLIDER_AUTOPLAY || false
							});
						}
					}
				},

				hotSpotHover: function () {
					$('.js-fj-hotspot-ring').hover(function () {
						var $this = $(this),
						$hotSpot = $this.closest('.fj-hotspot');
						$hotSpot.toggleClass('hotspot-hover');
					});
				}
			};

			$(window).on('resize', FJ_OnTour.debounce(function () {
				FJ_OnTour.responsiveSlider();
			}, 50));

			$ (document).ready(function () {
				FJ_OnTour.init();
			});
		}
	}
};

module.exports = ontour;