'use strict';

/**
 * @function
 * @description Initializes the tooltip-content and layout
 */
exports.init = function () {
	if ($(document).tooltip) {
		$(document).tooltip({
			items: '.tooltip',
			track: true,
			content: function () {
				return $(this).find('.tooltip-content').html();
			}
		});
		$('body').on('click', '.close-tooltip' , function (e) {
			e.preventDefault();
			$('.tooltop').tooltip('close');
		});
		// if tooltip link is clicked, do not send user to the page
		$('body').on('click', '.pt_checkout .tooltip' , function (e) {
			e.preventDefault();
		});
		$('.share-link').on('click', function (e) {
			e.preventDefault();
			var target = $(this).data('target');
			if (!target) {
				return;
			}
			$(target).toggleClass('active');
		});
	}
};
