'use strict';

var addToCart = require('./addToCart'),
	ajax = require('../../ajax'),
	image = require('./image'),
	tooltip = require('../../tooltip'),
    progress = require('../../progress'),
	util = require('../../util');
// function toggle () {
// 	$('.must-select').each(function () {
// 		$(this).find('.header-toggle').html($(this).find('li.selected').html());
// 		$(this).find('.content').hide();
// 	});
// 	$('.must-select li.selected').closest('.must-select').removeClass('.not-selected').addClass('selected');
// 	$('.header').click(function () {
// 		var that = this;
// 		//$('.content:visible').slideUp('slow');
// 		$('.header span.expanded')
// 			.removeClass('expanded')
// 			.addClass('collapsed');
// 		$(this)
// 			.next('.content:hidden')
// 			.slideDown('slow', function () {
// 				$('span.collapsed', that)
// 					.removeClass('collapsed')
// 					.addClass('expanded');
// 			});
// 	});
// }


module.exports = function () {
	var $addToCart = $('#add-to-cart'),
		$addAllToCart = $('#add-all-to-cart'),
		$productSetList = $('#product-set-list');

    var updateMainProductImages = function (element) {
        if (element.hasClass('swatchanchor')) {
            var product = element;
        } else {
            var product = element.closest('.product-set-item-wrapper').find('.product-set-image');
        }

        if (product.length === 0) {
            return false;
        }
        var mainImagesData = JSON.parse(product.attr('data-product-main-images'));
        var htmlOutput = '';

        for (var i = 0; i < mainImagesData.length; i++) {
            var img = mainImagesData[i];

            var thumbClass = '';
            if (i === 0) {
                thumbClass = ' first-thumb selected';
            } else if (i === mainImagesData.length - 1) {
                thumbClass = ' last-thumb';
            }

            var hiRes = img.url || '';
            var href = 'href="' + hiRes + '" target="_blank"';
            htmlOutput += '<div class="thumb' + thumbClass + '">'
                            + '<a ' + href + ' class="product-image main-imagess" title="' + img.alt + '">'
                                + '<img itemprop="image" class="primary-image ps-primary-image lazyload" data-src="' + img.url + '" data-srcset="' + img.url + '" alt="' + img.alt + '"/>'
                            + '</a>'
                        + '</div>'
        }

        $('.product-image-container .regular.slider').slick('destroy');
        $('.product-image-container .regular.slider').removeClass('product-set-initial').html(htmlOutput);
    };

    if (window.innerWidth < 880) {
		$('.progress').addClass('mobile').removeClass('desktop');
	}
	if (window.innerWidth > 879) {
		$('.progress').addClass('desktop').removeClass('mobile');
	}

    function initProgressSlider($slideItems) {
        var $slidesToShow = 4;
        $('.progress').slider({
            min: 0,
            max: ($slideItems - $slidesToShow),
            slide: function (event, ui) {
                var slick = $('.product-thumbnails.product-set-thumbnails .carousel').slick('getSlick');
                var goTo = ui.value * slick.slideCount / $slideItems;
                $('.product-thumbnails.product-set-thumbnails .carousel').slick('goTo', goTo);
            }
        });
    };

    var progressSlider = function () {
        var $slideItems = $('.product-thumbnails.product-set-thumbnails .carousel').find('.thumb').length;
        $('.product-thumbnails.product-set-thumbnails .carousel').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
            if (nextSlide >= currentSlide && nextSlide != (slick.slideCount - 1)) {
                $('.progress').slider('option', 'value', (currentSlide + 1));
            } else {
                $('.progress').slider('option', 'value', nextSlide);
            }
        });

        $('.product-thumbnails.product-set-thumbnails .carousel').on('afterChange', function (event, slick, currentSlide) {
            if (currentSlide !== $('.progress').slider("value")) {
                $('.progress').slider('option', 'value', currentSlide);
            }
        });

        initProgressSlider($slideItems);
    };

	var updateAddToCartButtons = function () {
		if ($productSetList.find('.add-to-cart[disabled]').length > 0) {
			$addAllToCart.attr('disabled', 'disabled');
			// product set does not have an add-to-cart button, but product bundle does
			$addToCart.attr('disabled', 'disabled');
		} else {
			$addAllToCart.removeAttr('disabled');
			$addToCart.removeAttr('disabled');
		}
	};

	if ($productSetList.length > 0) {
		updateAddToCartButtons();

		// delete for product sets
		$('#design-your-own-swatch').remove();
	}

	var updateContent = function (href, container, thisElement) {
        progress.show($('#pdpMain'));
		var qty = container.find('form input[name="Quantity"]').first().val();
		ajax.load({
			url: util.appendParamToURL(href, 'Quantity', isNaN(qty) ? '1' : qty),
			target: container,
			callback: function (data) {
				if (data) {
					updateAddToCartButtons();
					addToCart(container);
					tooltip.init();
					// toggle();
					if (thisElement) {
						var largeImg = thisElement.data('lgimg');
						var thumbMobileSTL = JSON.parse(thisElement.attr('data-product-thumb-mobile-stl-image'));
						var thumbMobileLP = JSON.parse(thisElement.attr('data-product-thumb-mobile-lp-image'));
						var thumbDesktopSTL = JSON.parse(thisElement.attr('data-product-thumb-desktop-stl-image'));
						var thumbDesktopLP = JSON.parse(thisElement.attr('data-product-thumb-desktop-lp-image'));
                        var currentProductId = container.attr('data-product-id');
                        var currentProductDesktopImage = $('.product-set .product-set-product-main-images .ps-product-primary-image[data-product-id="' + currentProductId + '"]');
                        var currentProductMobileImage = $('.product-set .product-set-thumbnails .productthumbnail[data-product-id="' + currentProductId + '"]');
						if (!largeImg) { return; }
						image.setMainImage(largeImg);
                        var thumbMobile = thumbMobileSTL.url.length > 0 ? thumbMobileSTL.url : thumbMobileLP.url;
                        var thumbDesktop = thumbDesktopSTL.url.length > 0 ? thumbDesktopSTL.url : thumbDesktopLP.url;

                        currentProductDesktopImage.attr({src: thumbDesktop, srcset: thumbDesktop});
                        currentProductMobileImage.attr('src', thumbMobile);
					}
					// delete for product sets
					$('#design-your-own-swatch').remove();
				}
                progress.hide();
			}
		});
	};

	// click on swatch for product set
	$productSetList.on('click', '.product-set-item .swatchanchor', function (e) {
		e.preventDefault();
		if ($(this).parents('li').hasClass('unselectable')) { return; }
		var container = $(this).closest('.product-set-item');
		if (util.getViewport() >= 880) {
			var $thumbnails = $('#thumbnails');
			$thumbnails.find('.thumb').removeClass('selected');
		}
		var res = $(this).attr('href').split('Product-Variation')[1];
		var url = Urls.getSetItem + res;
		// set the main image
		updateContent(url, container, $(this));
        updateMainProductImages($(this));
        image.setupCarousel();
        $('.progress').removeClass('mobile desktop');
	});
	$productSetList.on('click', '.product-set-details .custom-select li', function () {
		var container = $(this).closest('.product-set-item');
		var res = $(this).attr('value').split('Product-Variation')[1];
		var url = Urls.getSetItem + res;
		updateContent(url, container);
	});

    $(document).on('click', '.product-set-item .shop-this-item', function () {
        var currentProduct = $(this).closest('.product-set-item').attr('data-product-id');
        $('.product-set-item').removeClass('active');
        $(this).closest('.product-set-item').addClass('active');
        updateMainProductImages($(this));
        image.setupCarousel();
        $('.progress').removeClass('mobile desktop');
        $('.product-set-mobile-sticky-bar').find('.thumb').removeClass('is-active');
        $('.product-set-mobile-sticky-bar .carousel .thumb[data-product-id="' + currentProduct + '"]').addClass('is-active');
        setTimeout(function () {
            var offset = $('.product-set .product-set-list .product-set-item[data-product-id="' + currentProduct + '"]').offset();
            var barHeight = $('.product-set-mobile-sticky-bar').outerHeight(true) + 105;
            window.scrollTo({
                top: offset.top - barHeight,
                left: 0,
                behavior: "smooth",
            });
        }, 300);
    });

    $(document).on('click', '.product-set-product-main-images .ps-product-primary-image, .product-thumbnails.product-set-thumbnails .productthumbnail', function (e) {
        e.preventDefault();
        var currentProduct = $(this).attr('data-product-id');
        $(this).closest('.carousel').find('.thumb').removeClass('is-active');
        $(this).closest('.thumb').addClass('is-active');
        $('.product-set .product-set-list .product-set-item[data-product-id="' + currentProduct + '"]').find('.shop-this-item').trigger('click');
    });

    var productStickyBar = function () {
        var stickyBarPosition = $('.product-set-mobile-sticky-bar-wrapper').offset();
        var stickyBarHeight = $('.product-set-mobile-sticky-bar').outerHeight(true);
        $('.product-set-mobile-sticky-bar-wrapper').css('height', stickyBarHeight + 'px');
        if ((stickyBarPosition.top - 105) <= window.scrollY) {
            $('.product-set-mobile-sticky-bar').addClass('is-sticky');
        } else {
            $('.product-set-mobile-sticky-bar').removeClass('is-sticky');
        }
    }

    $(window).on('load', function () {
        progressSlider();
        productStickyBar();
    });

    $(window).on('resize', function () {
        productStickyBar();
	});

    $(window).on('scroll', function () {
        productStickyBar();
    });
};
