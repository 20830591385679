'use strict';

var addToCart = require('./addToCart'),
	customize = require('./customize'),
	ajax = require('../../ajax'),
	image = require('./image'),
	progress = require('../../progress'),
	productStoreInventory = require('../../storeinventory/product'),
	tooltip = require('../../tooltip'),
	sizeguide = require('../../sizeguide'),
	dialog = require('../../dialog'),
	util = require('../../util'),
    validator = require('../../validator'),
    notifyme = require('../../notifyme');

/**
 * @description update product content with new variant from href, load new content to #product-content panel
 * @param {String} href - url of the new product variant
 **/
var updateContent = function (href, $this) {
	var $pdpForm = $this.find('.pdpForm');
	var qty = $pdpForm.find('select[name="Quantity"]').first().val();
	var params = {
		Quantity: isNaN(qty) ? '1' : qty,
		format: 'ajax',
		productlistid: $pdpForm.find('input[name="productlistid"]').first().val()
	};

	progress.show($('#pdpMain'));
	window.isUpdateContent = true;
	$this.find('.product-main-info > .product-top-data').remove();
	if ($this.find('.product-main-info .pdp-hero').length < 1) {
		$this.find('.product-main-info .breadcrumbs-mobile').addClass('short');
	};

	ajax.load({
		url: util.appendParamsToUrl(href, params),
		target: $this.find('#product-content'),
		callback: function () {
			var $productMainContainer = this.target.find('.product-main-info'),
				$productTopData = this.target.find('.product-detail .product-top-data'),
				$quickview = $('#QuickViewDialog');

			if ($productMainContainer.length && !$quickview.length) {
				if (util.getViewport() < 880) {
					if ($productTopData.length) {
						$productTopData.prependTo($productMainContainer);
					}
				} else {
					if (!$productTopData.length) {
						$productTopData = $('.product-top-data');
						$productTopData.prependTo($('#product-content'));
					}
				}
			}

			if ($quickview.length > 0) {
				$quickview.find('.qv-product-price').empty();
				$quickview.find('.qv-price').prependTo('.qv-product-price');
			}

			if (this.target.find('.product-main-info .pdp-hero').length < 1) {
				this.target.find('.product-main-info .breadcrumbs-mobile').addClass('short');
			};

			addToCart();
			customize();
			if (SitePreferences.STORE_PICKUP) {
				productStoreInventory.init();
			}
			image.replaceImages();
			tooltip.init();
			image.setupCarousel();
			$('#free-returns').on('click', function (e) {
				e.preventDefault();
				dialog.open({
					url: $(e.target).attr('href')
				});
			});
			$('#styleID').html($('#itemID').html());
			if (this.target.find('#add-to-cart').hasClass('disabled')) {
				if (this.target.find('.product-detail .select-size .custom-select li').length == 1 && !this.target.find('.product-detail .select-size  .custom-select li').hasClass('selected')) {
					this.target.find('.product-detail .select-size .custom-select li').trigger('click');
				}
				if (this.target.find('.product-detail .select-width .custom-select li').length == 1 && !this.target.find('.product-detail .select-width  .custom-select li').hasClass('selected')) {
					this.target.find('.product-detail .select-width .custom-select li').trigger('click');
				}
			}
            updateWishListURL();
            this.target.find('.size-chart-link a').on('click', function (e) {
                e.preventDefault();
                dialog.open({
                    url: $(e.target).attr('href'),
                    options: {
                        width: 768
                    }
                });
            });
            if (this.target.find('.start-design').text().length > 43) {
                this.target.find('.product-variations').addClass('start-with');
            }
            validateGiftCard();
			if (window.Klarna && window.Klarna.OnsiteMessaging) {
				var klarnaPlacement = document.querySelector('klarna-placement');
				var price = this.target.find('.price-sales').last().text().trim();
				klarnaPlacement.setAttribute('data-purchase-amount', (price.replace(/[^\d.-]+/g, '') * 100).toFixed());
				window.Klarna.OnsiteMessaging.refresh()
			}
			sizeguide.init();
            this.target.find('.required').on('blur', function () {
                $(this).valid();
			});
			if (this.target.find('.cart-buttons-container .apple-pay-button').length && !window.ApplePaySession) {
				this.target.find('.apple-pay-button').remove();
			};
            rerenderPowerReviews();
            // After ajax call validator section doesn't work. Reinit it
            validator.init();
            tooltipAsset();
			if ($quickview.length > 0) {
				var $swatches = $quickview.find('ul.swatches.must-select'),
					$swatch = $swatches.find('li.selectable'),
					$slides,
					selectedSlide = 1;
				if ($swatches.hasClass('customizable-swatches')) {
					if ($(window).width() < '425') {
						$slides = 4;
						if($('#QuickViewDialog').find('ul.swatches li').length > 5) {
							selectedSlide = 2;
						}
					} else {
						$slides = 6;
					}
					
				} else {
					if ($(window).width() < '425') {
						$slides = 4;
						if($('#QuickViewDialog').find('ul.swatches li').length > 5) {
							selectedSlide = 2;
						}
					} else {
						$slides = 5;
					}
				}
				if ($swatch.length > $slides && !$swatches.hasClass('slick-initialized')) {
					$swatches.parents().find('.attribute.my-designs-line').last().not('.overflow').addClass('overflow');
					$swatches.slick({
						slidesToScroll: 1,
						slidesToShow: $slides,
						variableWidth: false,
						infinite: false,
						slide: 'li.selectable',
						responsive: [
							{
								breakpoint: 425,
								settings: {
									slidesToShow: 4,
									slidesToScroll: 1
								}
							}
						]
					});
				}

				setTimeout(function() {
					if ($swatches.hasClass('slick-initialized')) {
						var selected = $swatches.find('.selected[aria-hidden="true"]'),
							currentSlide = selected.data('slick-index'),
							perPage = $slides,
							slides = $swatches.find('.slick-slide').length,
							pages = slides - perPage,
							i = (currentSlide > 0 ? currentSlide/perPage : 0),
							selectedSlide = Math.ceil(i+i*i);

						if (selectedSlide > pages) {
							selectedSlide = pages;
						}
						
						if (selected.length > 0) {
							$swatches.slick('slickGoTo', selectedSlide);
						}
					}
				}, 500);

				if (util.isMobile()) {
					if ($('.promos-quickview').length > 1) {
						$('.product-col-2 .promos-quickview').remove();
					} else {
						return;
					}
				}
			}
		}
	});
};

function rerenderPowerReviews() {
    // if snippet is not rendered and pwr, PRConfig are available
    // then re-render power review
    if ($('#reviewssnippet').html() == '' && window.pwr && window.PRConfig) {
        window.pwr('render', window.PRConfig);
    }
}

function tooltipAsset() {
    $('#tooltip-variant').on('click', function (e) {
        e.preventDefault();
        var $toolvarModal = $('.tooltip-variant-modal');
        $toolvarModal.dialog({
            height: '375',
            modal: true,
            overlay: {
                opacity: 0.3,
                background: 'black'
            },
            dialogClass: 'tooltip-variant-dialog',
            draggable: false,
            resizable: false,
            width: '375',
            position: {
                my: 'center',
                at: 'center',
                of: window
            },
            close: function () {
                $toolvarModal.dialog('destroy');
            }
        });
    });
}

function expandCustomSelect(e) {
	$('.content:visible').slideUp('slow');
	$(this)
		.next('.content:hidden')
		.slideDown('slow');
}

function updateWishListURL() {
    if (typeof WISHLIST_URL !== 'undefined') {
        $('.secondary.add-to-wishlist').attr('href', WISHLIST_URL);
    }

    $('.add-to-wishlist').on('click', customize.addToWishlist);
}

function evaluateGCFields(requiredInputs, emojiField, requiresLetterInputs) {
	var enable = checkRequiredFields(requiredInputs);
	var regex = {
			containsLetter: /[A-Za-z]+/,
			validGiftcardText: /^[a-zA-Z0-9~`!@#\$%\^&\*\(\)_\-\+={\[\}\]\|:;"'<,>\.\? ]*$/,
			validGiftcardSmile: /:\-\)|:\)|:\-\]|:\]|:\-3|:3|:\->|:>|8\-\)|8\)|:\-\}|:\}|:o\)|:c\)|:\^\)|=\]|=\)|:\-D|:D|8\-D|8D|x\-D|xD|X\-D|XD|=D|=3|B\^D|:\-\)\)|:\-\(|:\(|:\-c|:c|:\-< |:<|:\-\[|:\[|>:\[|:\\}|:@|>:\(|:'\-\(|:'\(|:'\-\)|:'\)|D\-':|D:<|D:|D8|D;|D=|DX|:\-O|:O|:\-o|:o|:\-0|8\-0|>:O|:\-\*|:\*|:�|;\-\)|;\)|\*\-\)|\*\)|;\-\]|;\]|;\^\)|:\-,|;D|:\-P|:P|X\-P|x\-p|:\-p|:p|:\-b|:b|d:|=p|>:P|:\-\.|:L|=L|:S|:\$|:\-X|:X|:\-#|:#|:\-&|:&|O:\-\)|O:\)|0:\-3|0:3|0:\-\)|0:\)|0;\^\)|>:\-\)|>:\)|\}:\-\)|\}:\)|3:\-\)|3:\)|>;\)|:\-J|#\-\)|%\-\)|%\)|:\-###\.\.|:###\.\.|',:\-l|<_<|>_>|@\};\- |@\}\->\-\- |@\}\-;\-'\-\-\- |@>\-\->\-\-|5:\-\)|~\(_8\^\(I\)|=:o\]|7:\^\]|,:\-\)|<3|><>|<\*\)\)\)\-\\}|><\(\(\(\*>|v\.v|O_O|o\-o|O_o|o_O|o_o|O\-O|>\.<|\^5|>_>\^ \^<_<|\( \^\^\)|_U~~|\( \^\^\)|\(Q\)\)|\)\)><<|<\*\)\)|>=<|\(>_<\)|\(>_<\)>|\(';'\)|\(\^_\^;\)|\(\-_\-;\)|\(~_~;\)|\^\^;|\^_\^;|\(#\^\.\^#\)|\(\^\^;\)|\(\-_\-\)zzz|\(\^_\-\)|\(\(\+_\+\)\)|\(\+o\+\)|\^_\^|\(\^o\^\)|\(__\)|_\(\._\.\)_|_\(_\^_\)_|<\(_ _\)>|<m\(__\)m>|m\(__\)m|m\(_ _\)m|\('_'\)|\(T_T\)|\(;_;\)|\(;_;|\(;_:\)|\(;O;\)|\(:_;\)|\(ToT\)|;_;|;\-;|;n;|;;|Q\.Q|T\.T|TnT|QQ|Q_Q|\(\-\.\-\)|\(\-_\-\)|\(=_=\)|\(=\^�\^=\)|\(=\^��\^=\)|=_\^=|\(\.\.\)|\(\._\.\)|\^m\^|\(\-"\-\)|\(\^0_0\^\)|\( \^\)o\(\^ \)|\(\^O\^\)|\(\^o\^\)|\(\^?\^\)|\)\^o\^\(|:O |o_O|o_0|o\.O|\(o\.o\)|oO|uwu|UwU|\(\?_\?\)|>\^_\^<|<\^!\^>|\(\*\^0\^\*\)|\(\*_\*\)|\(\*_\*;|\(\+_\+\)|\(@_@\)|!\(\^\^\)!|\(\*\^\^\)v|\(p_\-\)|\(\(d\[\-_\-\]b\)\)|\(\^\^\)v|\(\^_\^\)v|\(~o~\)|\(~_~\)/g
	};
	requiresLetterInputs.each(function () {
		var testValue = $(this).val();
		if (!regex.containsLetter.test($.trim(testValue))) {
			enable = false;
		}
	});
	if (!enable) {
		return false;
	}
	var value = $(emojiField).val();
	var isValid = regex.validGiftcardText.test($.trim(value));
    if (!isValid) {
        return false;
    } else {
        if ($.trim(value).match(regex.validGiftcardSmile)) {
            var result = $.trim(value).match(regex.validGiftcardSmile);
            window.console.log(result);
            return false;
        }
    }
    return enable;
}

function checkRequiredFields(requiredInputs) {
	var isFormCompleted = true;
	requiredInputs.each(function () {
		if ($(this).val() === '') {
			isFormCompleted = false;
			return isFormCompleted;
		}
	});
	return isFormCompleted;
}
function validateGiftCard() {
	var $gcForm = $('#pdpMain .gcForm');
	if ($gcForm.length > 0) {
        var $addGCToCart = $('#add-gc-to-cart');
		var $requiredInputs = $gcForm.find('.required');
		var $requiresLetterInputs = $gcForm.find('.requiresLetter');
		var $perMsg = $gcForm.find('textarea.emojiless');
		// This checks anything entered into the textarea.
		$perMsg.on("input", function () {
			var enableA2C = evaluateGCFields($requiredInputs, $perMsg, $requiresLetterInputs);
			if (enableA2C) {
				$addGCToCart.removeClass('disabled');
			} else {
				$addGCToCart.addClass('disabled');
			}
		});
		$requiredInputs.on('change', function () {
			if (evaluateGCFields($requiredInputs, $perMsg, $requiresLetterInputs)) {
				$addGCToCart.removeClass('disabled');
			} else {
				$addGCToCart.addClass('disabled');
			}
		});
		$('#giftcardPurchaseAmount').on('change', function () {
			if ($(this).valid() && $(this).val() !== '') {
				$('#giftcardPurchaseAmountOption').removeAttr('required').removeClass('required').val('').blur();
				$requiredInputs = $gcForm.find('.required');
				if (evaluateGCFields($requiredInputs, $perMsg, $requiresLetterInputs)) {
					$addGCToCart.removeClass('disabled');
				} else {
					$addGCToCart.addClass('disabled');
				}
			} else {
				$('#giftcardPurchaseAmountOption').attr('required', 'required').addClass('required');
				$requiredInputs = $gcForm.find('.required');
				$addGCToCart.addClass('disabled');
			}
		});
		$('#giftcardPurchaseAmountOption').on('change', function () {
			$('#giftcardPurchaseAmount').val('');
			$(this).attr('required', 'required').addClass('required');
			$requiredInputs = $gcForm.find('.required');
			if (evaluateGCFields($requiredInputs, $perMsg, $requiresLetterInputs)) {
				$addGCToCart.removeClass('disabled');
			} else {
				$addGCToCart.addClass('disabled');
			}
		});
	}

}

module.exports = function () {
	var $pdpMain = $('#pdpMain');
	
	if ($('#QuickViewDialog').length > 0) {
		$pdpMain = $('#QuickViewDialog')
	}

	//notify me dialog
	notifyme.init();
	$pdpMain.on('mouseenter mouseleave', '.swatchanchor', function () {
		var largeImg = $(this).data('lgimg'),
			$imgZoom = $pdpMain.find('.main-image'),
			$mainImage = $pdpMain.find('.primary-image');

		if (!largeImg) { return; }
		// store the old data from main image for mouseleave handler
		$(this).data('lgimg', {
			hires: $imgZoom.attr('href'),
			url: $mainImage.attr('src'),
			alt: $mainImage.attr('alt'),
			title: $mainImage.attr('title')
		});
		// set the main image
		image.setMainImage(largeImg);
	});

	// click on swatch - should replace product content with new variant
	$pdpMain.on('click', '.product-detail .swatchanchor', function (e) {
		e.preventDefault();
		if ($(this).parents('li').hasClass('unselectable')) { return; }
		updateContent(this.href, $pdpMain);
		window.history.replaceState('', $(document).find("title").text(), $(this).attr('data-product-url'));
	});

	$pdpMain.on('click', '.product-detail .custom-select li:not(.inactive)', function () {
		updateContent($(this).attr('value'), $pdpMain);
	});

	$pdpMain.on('click', '.product-detail .custom-select li.inactive', function (e) {
		e.preventDefault();
		if (SitePreferences.SOLD_OUT_PRODUCTS_ENABLED) {
			var elementCount = $(this).parent().find('li').length;
			var inactiveElementCount = $(this).parent().find('li.inactive').length;
			var isAllInactive = elementCount == inactiveElementCount;
			if (isAllInactive) {
				return;
			}
		}
		if ($('#add-to-cart').hasClass('locked') || isAllInactive) {
			return;
		}
		$(this).closest('.warning-box').find('.custom-li.selected').removeClass('selected');
		$(this).closest('.warning-box').addClass('error');
		$('.low-inventory-msg').text('Please select a ' + $(this).closest('.must-select').attr('data-attribute'));
		$(document).find('.custom-select-field .select-header').addClass('error');
		if (!$('#add-to-cart').hasClass('disabled')) {
			$('#add-to-cart').text(Resources.SELECT_A_SIZE);
			$('#add-to-cart').addClass('disabled');
		}
	});

	$pdpMain.on('change', '#SpecialPlayNumber', function () {
		if ($(this).val() != '') {
			updateContent($(this).val(), $pdpMain);
		} else {
			$('#add-to-cart').addClass('disabled');
		}
	});

	$pdpMain.on('keydown', '.product-detail .custom-select li', function (e) {
		var key = e.keyCode ? e.keyCode : e.which;
		var KEY_CODE_ENTER = 13,
			KEY_CODE_SPACE = 32;
		if ([KEY_CODE_ENTER, KEY_CODE_SPACE].indexOf(key) > -1) {
			e.preventDefault();
			updateContent($(this).attr('value'), $pdpMain);
		}
	});

	$pdpMain.on('click', '.product-detail #play-hand .button', function () {
		var btn = $(this);
		if (!btn.hasClass('disabled')) {
			$('#va-orientation option').each(function () {
				if ($(this).html().trim() === btn.data('attr-value')) {
					updateContent($(this).attr('value'), $pdpMain);
				}
			});
		}
	});

	// change drop down variation attribute - should replace product content with new variant
	$pdpMain.on('change', '.variation-select', function (e) {
		e.preventDefault();
		if ($(this).val().length === 0) { return; }
		updateContent($(this).val(), $pdpMain);
	});

	$pdpMain.on('keydown', '.header', function (e) {
		var key = e.keyCode ? e.keyCode : e.which;
		var KEY_CODE_ENTER = 13,
		KEY_CODE_SPACE = 32,
		actionKeys = [KEY_CODE_ENTER, KEY_CODE_SPACE];
		if (actionKeys.indexOf(key) > -1) {
			e.preventDefault();
			expandCustomSelect.call(this);
		}
	});

    if ($('#add-to-cart').hasClass('disabled')) {
        if ($('.product-detail .select-size .custom-select li').length == 1 && !$('.product-detail .select-size  .custom-select li').hasClass('selected')) {
            window.skipRenderPROnLoad = true;
            $('.product-detail .select-size .custom-select li').trigger('click');
        }

        if ($('.product-detail .select-width .custom-select li').length == 1 && !$('.product-detail .select-width  .custom-select li').hasClass('selected')) {
            window.skipRenderPROnLoad = true;
            $('.product-detail .select-width .custom-select li').trigger('click');
        }
    }

   $('.size-chart-link a').on('click', function (e) {
        e.preventDefault();
        dialog.open({
            url: $(e.target).attr('href'),
            options: {
                width: 768
            }
        });
    });

    updateWishListURL();
    validateGiftCard();
    tooltipAsset();
    // Run validation GC fields after loading page.
    $('#giftcardPurchaseAmount').trigger('change'); // Remove required from field giftcardPurchaseAmountOption if giftcardPurchaseAmount has value
    $('#pdpMain .gcForm .required').last().trigger('change'); // run validation for gc fields
};
