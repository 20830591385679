'use strict';

var imagesLoaded = require('imagesloaded'),
	util = require('./util'),
	quickview = require('./quickview');

function initQuickViewButtons() {
	if (SitePreferences.DISABLE_QUICK_VIEW) {
		return;
	}

	var $tilesContainer = $('.fj-rp-slider-wrapper').length ? $('.fj-rp-slider-wrapper') : $('.tiles-container'),
		productTile = $('.fj-rp-slide').length ? '.fj-rp-slide' : '.product-tile:not(".active-product")';

	$tilesContainer.on('mouseenter', productTile, function () {
		var $qvButton = $(this).find('.stl-quickview') ? $(this).find('.stl-quickview:not(".active-product"), button.quickview') : $(this).find('button.quickview');

		if (util.isMobile() || $qvButton.length === 0) {
			return;
		}

		$(this).addClass('qv-visible');

		var $link = $('.fj-rp-slide').length ? $(this) : $(this).find('.thumb-link');
		$qvButton.attr({
			'href': $link.attr('href'),
			'title': $link.attr('title')
		});

		$qvButton.off('click').on('click', function (e) {
			e.preventDefault();

			if ($('.shop-the-look').length > 0) {
				var $productTile = $(this).closest('.stl-product');

				$productTile.addClass('qv-active');
			}
			// Show loading indicator
			var $loading = $('<div class="quickview-loading"></div>');
			$loading.appendTo(document.body);

			quickview.show({
				url: $(this).attr('href'),
				source: 'quickview'
			});
		});

	}).on('mouseleave', productTile, function () {
		$(this).removeClass('qv-visible');
	});

	$(productTile).each(function(){
		var $qvButton = $(this).find('.stl-quickview') ? $(this).find('.stl-quickview:not(".active-product"), button.quickview') : $(this).find('button.quickview'),
			$link = $('.fj-rp-slide').length ? $(this) : $(this).find('.thumb-link');

		if (!util.isMobile() || $qvButton.length === 0) {
			return;
		}

		$qvButton.attr({
			'href': $link.attr('href'),
			'title': $link.attr('title')
		});
		$qvButton.off('click').on('click', function (e) {
			e.preventDefault();

			// Show loading indicator
			var $loading = $('<div class="quickview-loading"></div>');
			$loading.appendTo(document.body);

			quickview.show({
				url: $(this).attr('href'),
				source: 'quickview'
			});
		});
	})
}

function productTileHover() {
    var $searchResults = $('.pt_product-search-result');
    $searchResults.on('mouseenter mouseleave', '.thumb-link', function (event) {
        var secondaryImg = $(this).data('secondary-image'),
            mainImage = $(this).find('img').attr('src');
			if (util.getViewport() > 1680) {
				secondaryImg = secondaryImg.replace('sw=400', 'sw=520');
				mainImage = mainImage.replace('sw=300&sh=300', 'sw=520&sh=520');
			}
			if (secondaryImg !== '') {
                $(this).data('secondaryImage', mainImage);
                $(this).find('img').attr('src', secondaryImg);
                $(this).find('img').attr('srcset', secondaryImg);
                event.stopImmediatePropagation();
            }
    });
}

function gridViewToggle() {
	$('.toggle-grid').on('click', function () {
		$('.search-result-content').toggleClass('wide-tiles');
		$(this).toggleClass('wide');
	});
}

function swatchImageCkick() {
	$('.swatch-list').on('mouseleave', function () {
		// Restore current thumb image
		var $tile = $(this).closest('.product-tile'),
			$thumb = $tile.find('.product-image .thumb-link img').eq(0),
			data = $thumb.data('current'),
			masterPlpTextBadge = $tile.find('.plp-text-badge.master-badge'),
			defaultPlpTextBadge = $tile.find('.plp-text-badge.default-badge'),
			defaultPlpTextBadgeValue = $tile.find('.swatch.default-variation').attr('data-plptextbadge'),
			PlpTextBadge = $tile.find('.plp-text-badge span');

		if (data && data.hasOwnProperty('src')) {
			$(this).find('.swatch.selected').removeClass('selected');
			$(this).find('.swatch.original').addClass('selected');
            var thmsrc;
            if (util.getViewport() > 1680) {
                thmsrc = data.srcxl;
            } else if (util.getViewport() > 1160 && util.getViewport() < 1679) {
                thmsrc = data.srclg;
            } else {
                thmsrc = data.src;
            }

			$thumb.attr({
				src: thmsrc,
				srcset: thmsrc,
				alt: data.alt,
				title: data.title
			});
		}
		if (masterPlpTextBadge.length === 0) {
			if (defaultPlpTextBadge.length > 0) {
				PlpTextBadge.text(defaultPlpTextBadgeValue);
			} else {
				PlpTextBadge.removeClass('plp-badge-value');
				PlpTextBadge.empty();
			}
		}

		// update price for original selected swatch
		var $originalSelectedSwatch = $(this).find('.swatch.original.selected');
		var productSwatchId = $originalSelectedSwatch.attr('data-productid');
		var $productInfoList = $originalSelectedSwatch.closest('.product-swatches').find('div[data-type="productInfoList"]');
		var hoverFullPriceEnabled = $productInfoList.attr('data-hoverfullpriceenabled') == "true" ? true : false;
		if ($productInfoList.length > 0) {
			var productSelector = 'div[data-productid="' + productSwatchId + '"]';
			var $hoveredSwatchEl = $productInfoList.find(productSelector);
			if ($hoveredSwatchEl.length > 0) {
				var updatedPriceHtml = $hoveredSwatchEl.html();
				$(this).closest('.product-tile-details').find('.product-pricing').html(updatedPriceHtml);

				// update name
				var updatedProductName = $hoveredSwatchEl.attr('data-productname');
				if (updatedProductName) {
					$(this).closest('.product-tile-details').find('.product-name .name-link').text(updatedProductName);
				}
			}
		}
		if (hoverFullPriceEnabled) {
			// hide strikethrough price. It will appear after hover on swatch
			$(this).closest('.product-tile-details').find('.product-pricing .product-standard-price').removeClass('visually-hidden');
			$(this).closest('.product-tile-details').find('.product-pricing .product-standard-price').addClass('visually-hidden');
		}

	});
	$('.swatch-list .swatch').on('click', function (e) {
		if (!$(this).closest('.product-listing').length) { return; }
		e.preventDefault();
		if ($(this).hasClass('selected')) { return; }

		var $tile = $(this).closest('.product-tile');
		var $list = $(this).closest('.swatch-list');
		$list.find('.swatch.selected').removeClass('selected');
		$list.find('.swatch.original').removeClass('original');
		$(this).addClass('selected original');
		$tile.find('.thumb-link').attr('href', $(this).attr('href'));
		$tile.find('.name-link').attr('href', $(this).attr('href'));

		var data = $(this).children('img').filter(':first').data('thumb');
		var $gridTile = $(this).closest('.grid-tile');
		var productName = data.productName.length < 28 ? data.productName : data.productName.slice(0, 28) + '...';
		$gridTile.find('.name-link').text(productName);

		$.ajax({
			dataType: 'html',
			url: Urls.getProductTilePrice,
			data: 'pid=' + data.productID,
			xhrFields: {
				withCredentials: true
			},
			success: function (data) {
				$gridTile.find('.product-pricing').empty().html(data);
			}
		});

		var $thumb = $tile.find('.product-image .thumb-link img').eq(0);
		var currentAttrs = {
			src: data.src,
			alt: data.alt,
			title: data.title
		};
		$thumb.attr(currentAttrs);
		$thumb.data('current', currentAttrs);

        var $a = $tile.find('.product-image .thumb-link').eq(0);
        $a.data('secondary-image', data.secondaryImage);

	}).on('mouseenter', function (e, data) {
		var isInitialization = data && data.isInitialize ? true : false;
		// get current thumb details
		var $tile = $(this).closest('.product-tile'),
			$thumb = $tile.find('.product-image .thumb-link img').eq(0),
			data = $(this).children('img').filter(':first').data('thumb'),
			$selected = $tile.find('.swatch.selected'),
			selectedData = $selected.data('thumb') || data,
			current = $thumb.data('current'),
            mainsrc = data.src,
			dataPlpTextBadge = $(this).attr('data-plptextbadge'),
			masterPlpTextBadge = $tile.find('.plp-text-badge.master-badge'),
			PlpTextBadge = $tile.find('.plp-text-badge span');
            if (util.getViewport() > 1680) {
                mainsrc = data.srcxl;
            } else if (util.getViewport() > 1160 && util.getViewport() < 1679) {
                mainsrc = data.srclg;
            } else {
                mainsrc = data.src;
            }

		// If this is the first time, then record the current img
		if (!current) {
			$(this).addClass('original');
			$thumb.data('current', {
				src: selectedData.src,
				alt: selectedData.alt,
				title: selectedData.title
			});
		}

		// move the outline to the hovered swatch
		$selected.removeClass('selected');
		$(this).addClass('selected');

		// Set the tile image to the values provided on the swatch data attributes
		$thumb.attr({
			src: data.mainsrc,
			srcset: mainsrc,
			alt: data.alt,
			title: data.title
		});

		if (dataPlpTextBadge) {
			if (masterPlpTextBadge.length === 0) {
				PlpTextBadge.addClass('plp-badge-value');
				PlpTextBadge.text(dataPlpTextBadge);
			}
		} else {
			if (masterPlpTextBadge.length === 0) {
				PlpTextBadge.addClass('plp-badge-value');
				PlpTextBadge.empty();
			}
		}

		// update price for original selected swatch
		var productSwatchId = $(this).attr('data-productid');
		var $productInfoList = $(this).closest('.product-swatches').find('div[data-type="productInfoList"]');
		var hoverFullPriceEnabled = $productInfoList.attr('data-hoverfullpriceenabled') == "true" ? true : false;
		if ($productInfoList.length) {
			var productSelector = 'div[data-productid="' + productSwatchId + '"]';
			var $hoveredSwatchEl = $productInfoList.find(productSelector);
			if ($hoveredSwatchEl.length > 0) {
				var updatedPriceHtml = $hoveredSwatchEl.html();
				$(this).closest('.product-tile-details').find('.product-pricing').html(updatedPriceHtml);
			}

			// update name
			var updatedProductName = $hoveredSwatchEl.attr('data-productname');
			if (updatedProductName) {
				$(this).closest('.product-tile-details').find('.product-name .name-link').text(updatedProductName);
			}
		}
		// update price after initialization only
		if (isInitialization && hoverFullPriceEnabled) {
			$(e.target).closest('.product-tile-details').find('.product-pricing .product-standard-price').removeClass('visually-hidden');
			$(e.target).closest('.product-tile-details').find('.product-pricing .product-standard-price').addClass('visually-hidden');
		}
	});
}

/**
 * @private
 * @function
 * @description Toggles full swatch visibility on product tile, as default is collapsed, only showing 4 swatches max
 */
function showMoreSwatch() {
	$('.product-swatches').off('click', 'ul li.open-close');
	$('.product-swatches').on('click', 'ul li.open-close', function (e) {
		if (!$(this).parents('.pt_product-search-result').length) {
			e.preventDefault();
			$(this).parents('.product-swatches').toggleClass('expanded');
		}
	});
}

function initUnselectedSwatches() {
	$('.swatch-list').each(function () {
		if ($(this).find('.swatch').length > 0 && $(this).find('.swatch.selected').length === 0) {
			$(this).find('.swatch').eq(0).trigger('click');
		}
	});
}

/**
 * @private
 * @function
 * @description Initializes events on the product-tile for the following elements:
 * - swatches
 * - thumbnails
 */
function initializeEvents() {
    productTileHover();
	gridViewToggle();
	showMoreSwatch();
	swatchImageCkick();
	initUnselectedSwatches();
	dataLayer.push({'event': 'optimize.activate'});
	console.log('optimize.activate');

	$(document).ready(function() {
		var attempts = 0,
			maxAttempts = 100,
			checkExist = setInterval(function() {
			$('.tiles-container, .fj-rp-slider-wrapper').each(function() {
				if ($(this).hasClass('tiles-container')) {
					initQuickViewButtons();

					if ($(this).hasClass('search-result-items')) {
						clearInterval(checkExist);
						return false;
					}

				} else if ($(this).hasClass('fj-rp-slider-wrapper')) {
					var tabs = $('.tabs-rec-content').find('.tabs-toggle').length,
						sliders = $('.fj-rp-slider-wrapper').length;

					if (sliders === tabs) {
						initQuickViewButtons();
						clearInterval(checkExist);
						return false;
					}
				}
			});

			attempts++;
			if (attempts >= maxAttempts) {
				clearInterval(checkExist);
			}
		}, 100);
	});
}

/**
 * @private
 * @function
 * @description Toggle title filter panel on PLP
 */
function toggleRefineTitle() {
	var isHideRefinements = $('body').hasClass('hide-refinements');
	var isMobileRefinementsActive = $('body').hasClass('mobile-refinements-active');
	var refinementsToggle = $('#refinementsToggle');
	var buttonTextDesktop = isHideRefinements ? refinementsToggle.data('buttonTextShow') : refinementsToggle.data('buttonTextHide');
	var buttonTextMobile = isMobileRefinementsActive ? refinementsToggle.data('buttonTextHide') : refinementsToggle.data('buttonTextShow');
	refinementsToggle.find('.toggle-refine-title.display-desktop-only').html(buttonTextDesktop);
	refinementsToggle.find('.toggle-refine-title.display-mobile-only').html(buttonTextMobile);
}

exports.init = function () {
	initializeEvents();
	toggleRefineTitle();
};
module.exports.showMoreSwatch = showMoreSwatch;
module.exports.swatchImageCkick = swatchImageCkick;
module.exports.initQuickViewButtons = initQuickViewButtons;
