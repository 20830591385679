/* eslint-disable no-undef */
'use strict';

/**
 * @module int_amazonpay_sg/cartridge/js/amazon/amazonPay.js
 */

/**
 * Rendering Amazon Pay One Time button.
 *
 * @memberof amazonPay
 * @param {JQuery} $amazonPayButton - Amazon Pay button selector.
 * @returns {undefined}
 */
function renderAmazonPayOneTimeButton($amazonPayButton) {
    $.ajax({
        url: $amazonPayButton.data('rendering-url'),
        type: 'GET',
        dataType: 'JSON',
        success: function (renderAmazonButtonData) {
            if (renderAmazonButtonData.success) {
                amazon.Pay.renderButton('#' + $amazonPayButton.prop('id'), renderAmazonButtonData.amazonPayButtonConfig);
            }
        },
        error: function () {
            // If the signature request cannot be obtained,
            // the button will be hidden and the process will be terminated.
            $amazonPayButton.hide();
        }
    });
}

/**
 * Event click for Amazon Pay APB button.
 *
 * @private
 * @param {JQuery} $amazonPayButton - Amazon Pay button selector.
 * @param {Object} renderedAmazonPayButton - rendered Amazon Pay button.
 * @returns {undefined}
 */
function onClickAmazonPayApbButton($amazonPayButton, renderedAmazonPayButton) {
    renderedAmazonPayButton.onClick(function () {
        var csrfTokenName = $amazonPayButton.data('csrf-token-name');
        var csrfTokenValue = $amazonPayButton.data('csrf-token-value');
        var csrfErrorUrl = $amazonPayButton.data('csrf-error-url');
        var postData = {
            format: 'ajax'
        };
        postData[csrfTokenName] = csrfTokenValue;

        if ($amazonPayButton.prop('disabled')) {
            // The click event of the AmazonPay button cannot be controlled only by the disabled attribute,
            // so determine whether the button is disabled or not.
            return;
        }

        // SiteGenesis standard error messages have no display element if no error has occurred.
        // For this reason, AmazonPay errors have different elements than standard errors.
        // If a site perform an AmazonPay payment, it need to hide the standard error message.
        $('.error-form').empty().hide();

        $('#js-amazon-pay-apb-error-message').empty().hide();
        $amazonPayButton.prop('disabled', true);

        $.ajax({
            url: $amazonPayButton.data('checkout-url'),
            type: 'POST',
            dataType: 'JSON',
            data: postData,
            success: function (data) {
                if (data.error) {
                    location.href = csrfErrorUrl;
                    return;
                }
                if (!data.success) {
                    if (data.redirectUrl) {
                        location.href = data.redirectUrl;
                        return;
                    }

                    $('#js-amazon-pay-apb-error-message').text(data.errorMessage).show();
                    $amazonPayButton.prop('disabled', false);
                    return;
                }

                renderedAmazonPayButton.initCheckout(data.checkoutSignatureData);
            },
            error: function () {
                // If the creation of an order fails, reload the screen to bring the order up to date.
                location.reload();
            }
        });
    });
}

/**
 * Rendering Amazon Pay APB button.
 *
 * @private
 * @param {JQuery} $amazonPayButton - Amazon Pay button selector.
 * @returns {undefined}
 */
function renderAmazonPayApbButton($amazonPayButton) {
    $.ajax({
        url: $amazonPayButton.data('rendering-url'),
        type: 'GET',
        dataType: 'JSON',
        success: function (renderAmazonButtonData) {
            var renderedAmazonPayButton = amazon.Pay.renderButton('#' + $amazonPayButton.prop('id'), renderAmazonButtonData);

            onClickAmazonPayApbButton($amazonPayButton, renderedAmazonPayButton);
        },
        error: function () {
            // If the signature request cannot be obtained,
            // the button will be hidden and the process will be terminated.
            $amazonPayButton.hide();
        }
    });
}

/**
 * Bind editing links to Amazon Pay server.
 *
 * @memberof amazonPay
 * @returns {undefined}
 */
function bindEditingLinksToAmazonPayServer() {
    var $amazonPayEditShippingLink = $('.js-amazon-pay-shipping-edit-link');

    var existAmazonPayEditShippingLink = $amazonPayEditShippingLink.length > 0;
    if (existAmazonPayEditShippingLink) {
        amazon.Pay.bindChangeAction('.js-amazon-pay-shipping-edit-link', {
            amazonCheckoutSessionId: $amazonPayEditShippingLink.data('checkout-session-id'),
            changeAction: 'changeAddress'
        });
    }

    var $amazonPayEditPaymentLink = $('.js-amazon-pay-payment-edit-link');

    var existAmazonPayEditPaymentLink = $amazonPayEditPaymentLink.length > 0;
    if (existAmazonPayEditPaymentLink) {
        amazon.Pay.bindChangeAction('.js-amazon-pay-payment-edit-link', {
            amazonCheckoutSessionId: $amazonPayEditPaymentLink.data('checkout-session-id'),
            changeAction: 'changePayment'
        });
    }
}

/**
 * Init Amazon Pay assist payment relief dialog
 *
 * @memberof amazonPay
 * @returns {undefined}
 */
function initAmazonPayAssistPopUp() {
    var $amazonPayPopUp = $('.js-amazonpay-assist-popup');
    if ($amazonPayPopUp.length <= 0) {
        return;
    }

    var isShowAmazonPayAssistPaymentReliefPopUp = $amazonPayPopUp.data('isshowamazonpayassistpaymentreliefpopup');
    if (!isShowAmazonPayAssistPaymentReliefPopUp) {
        return;
    }

    var url = $amazonPayPopUp.data('amazonpayassistpopup-url');
    $.ajax({
        url: url,
        type: 'GET',
        dataType: 'JSON',
        success: function (data) {
            if (!data.success) {
                return;
            }

            var amazonPayAssistPaymentReliefPopUpConfig = data.amazonPayAssistPaymentReliefPopUpConfig;
            var amazonPayAssistPopUp = amazonpayAssist('assistPopUpAmazonPayButton', {
                type: amazonPayAssistPaymentReliefPopUpConfig.amazonPayAssistPopUpType,
                color: amazonPayAssistPaymentReliefPopUpConfig.amazonPayAssistPaymentReliefPopUpColor
            });
            if (amazonPayAssistPaymentReliefPopUpConfig.amazonPayAssistPaymentReliefPopUpFadeInSeconds) {
                amazonPayAssistPopUp.fadeIn(amazonPayAssistPaymentReliefPopUpConfig.amazonPayAssistPaymentReliefPopUpFadeInSeconds);
            }
            if (amazonPayAssistPaymentReliefPopUpConfig.isShowAmazonPayAssistPaymentReliefPopUpByMouseLeave) {
                amazonPayAssistPopUp.mouseLeave();
            }

            var amazonPayButtonConfig = data.amazonPayButtonConfig;

            // Both Amazon OneTimeButton and ApbButton use amazon.Pay.renderButton to render Amazon Pay button
            var renderedAmazonPayButton = amazon.Pay.renderButton('#assistPopUpAmazonPayButton', amazonPayButtonConfig);

            // Only Amazon ApbButton need to add onClick event after rendering AmazonPay button
            if (data.isAmazonPayApbEnabled) {
                var $amazonPayButton = $('#assistPopUpAmazonPayButton');
                $('#assistPopUpAmazonPayButton').data($amazonPayPopUp.data());
                onClickAmazonPayApbButton($amazonPayButton, renderedAmazonPayButton);
            }
        }
    });
}

module.exports = {
    init: function () {
        var $amazonPayCartOneTimeButton = $('.amazon-pay-cart-one-time-button');

        var existAmazonPayCartOneTimeButton = $amazonPayCartOneTimeButton.length > 0;
        if (existAmazonPayCartOneTimeButton) {
            $($amazonPayCartOneTimeButton).each(function () {
                renderAmazonPayOneTimeButton($(this));
            });
        }

        var $amazonPayMiniCartOneTimeButton = $('#amazon-pay-mini-cart-one-time-button');

        var existAmazonPayMiniCartOneTimeButton = $amazonPayMiniCartOneTimeButton.length > 0;
        if (existAmazonPayMiniCartOneTimeButton) {
            renderAmazonPayOneTimeButton($amazonPayMiniCartOneTimeButton);
        }

        var $amazonPayApbButton = $('#amazon-pay-cart-apb-button');

        var existAmazonPayApbButton = $amazonPayApbButton.length > 0;
        if (existAmazonPayApbButton) {
            renderAmazonPayApbButton($amazonPayApbButton);
        }

        bindEditingLinksToAmazonPayServer();

        initAmazonPayAssistPopUp();
    },

    renderAmazonPayOneTimeButton: renderAmazonPayOneTimeButton,
    bindEditingLinksToAmazonPayServer: bindEditingLinksToAmazonPayServer
};
